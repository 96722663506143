/*-----------------------------------------------------------------------------------

	Template Name: Andle - Bootstrap 5 HTML Template
    Author: codecarnival
    Author URL: https://themeforest.net/user/codecarnival
    Support: https://hasthemes.com/contact-us/
    Description: Andle - Bootstrap 5 HTML Template
    Version: 1.1

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Theme default CSS
	02. Component CSS
		2.1 - Button CSS
		2.2 - Form CSS
		2.3 - Blockquote CSS
	03. Header CSS
		3.1 - Header Main CSS
	04. Pages CSS (All Page Styles)
		4.1 - Slider CSS
		4.2 - Clients & About CSS
		4.3 - Why Choose & Counter CSS
		4.4 - Work CSS
		4.5 - Achieved CSS
		4.6 - Testimonial CSS
		4.7 - Skills CSS
		4.8 - Pricing CSS
		4.9 - Projects CSS
		4.10 - Projects Details CSS
		4.11 - Blog CSS
		4.12 - Blog Details CSS
		4.13 - Contact CSS
		4.14 - FAQ's CSS
	05. Widget CSS
		5.1 - Sidebar Widget CSS
		5.2 - Footer Widget CSS
	07. Footer CSS

-----------------------------------------------------------------------------------*/
/*!
 * Bootstrap v5.0.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;600;700;800;900&family=Poppins:wght@300;400;500;600;700;800;900&family=Zilla+Slab:wght@400;500;600;700&display=swap");
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #da2929;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #89715b;
  --bs-secondary: #6c757d;
  --bs-success: #f58344;
  --bs-info: #0dcaf0;
  --bs-warning: #feae01;
  --bs-danger: #da2929;
  --bs-light: #f8f9fa;
  --bs-dark: #090909;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.75);
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Zilla Slab", serif;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.34375rem + 1.125vw);
}

@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.1875rem;
  }
}

h2, .h2 {
  font-size: calc(1.3rem + 0.6vw);
}

@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.75rem;
  }
}

h3, .h3 {
  font-size: calc(1.27812rem + 0.3375vw);
}

@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.53125rem;
  }
}

h4, .h4 {
  font-size: calc(1.25625rem + 0.075vw);
}

@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.3125rem;
  }
}

h5, .h5 {
  font-size: 1.09375rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #89715b;
  text-decoration: underline;
}

a:hover {
  color: #6e5a49;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 15px);
  padding-left: var(--bs-gutter-x, 15px);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1200px;
  }
}

@media (min-width: 1600px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1200px;
  }
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.3125rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.3125rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.625rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.625rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 0.9375rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 0.9375rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.25rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.25rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 1.625rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 1.625rem;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 1.875rem;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 1.875rem;
}

.g-7,
.gx-7 {
  --bs-gutter-x: 2.1875rem;
}

.g-7,
.gy-7 {
  --bs-gutter-y: 2.1875rem;
}

.g-8,
.gx-8 {
  --bs-gutter-x: 2.5rem;
}

.g-8,
.gy-8 {
  --bs-gutter-y: 2.5rem;
}

.g-9,
.gx-9 {
  --bs-gutter-x: 2.8125rem;
}

.g-9,
.gy-9 {
  --bs-gutter-y: 2.8125rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 3.125rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 3.125rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-sm-7,
  .gx-sm-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-sm-7,
  .gy-sm-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-sm-8,
  .gx-sm-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-8,
  .gy-sm-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-9,
  .gx-sm-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-sm-9,
  .gy-sm-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-md-7,
  .gx-md-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-md-7,
  .gy-md-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-md-8,
  .gx-md-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-8,
  .gy-md-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-9,
  .gx-md-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-md-9,
  .gy-md-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-lg-7,
  .gx-lg-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-lg-7,
  .gy-lg-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-lg-8,
  .gx-lg-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-8,
  .gy-lg-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-9,
  .gx-lg-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-lg-9,
  .gy-lg-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xl-7,
  .gx-xl-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-xl-7,
  .gy-xl-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-xl-8,
  .gx-xl-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xl-8,
  .gy-xl-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xl-9,
  .gx-xl-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-xl-9,
  .gy-xl-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-xl-10,
  .gx-xl-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-xl-10,
  .gy-xl-10 {
    --bs-gutter-y: 3.125rem;
  }
}

@media (min-width: 1600px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.3125rem;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.3125rem;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.625rem;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.625rem;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 0.9375rem;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 0.9375rem;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.25rem;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.25rem;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 1.625rem;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 1.625rem;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 1.875rem;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 1.875rem;
  }
  .g-xxl-7,
  .gx-xxl-7 {
    --bs-gutter-x: 2.1875rem;
  }
  .g-xxl-7,
  .gy-xxl-7 {
    --bs-gutter-y: 2.1875rem;
  }
  .g-xxl-8,
  .gx-xxl-8 {
    --bs-gutter-x: 2.5rem;
  }
  .g-xxl-8,
  .gy-xxl-8 {
    --bs-gutter-y: 2.5rem;
  }
  .g-xxl-9,
  .gx-xxl-9 {
    --bs-gutter-x: 2.8125rem;
  }
  .g-xxl-9,
  .gy-xxl-9 {
    --bs-gutter-y: 2.8125rem;
  }
  .g-xxl-10,
  .gx-xxl-10 {
    --bs-gutter-x: 3.125rem;
  }
  .g-xxl-10,
  .gy-xxl-10 {
    --bs-gutter-y: 3.125rem;
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: rgba(255, 255, 255, 0.75);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: rgba(255, 255, 255, 0.75);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: rgba(255, 255, 255, 0.75);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.75);
  vertical-align: top;
  border-color: #dee2e6;
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #e7e3de;
  --bs-table-striped-bg: #dbd8d3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #d0ccc8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d6d2cd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #d0ccc8;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #fde6da;
  --bs-table-striped-bg: #f0dbcf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e4cfc4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ead5ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e4cfc4;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #ffefcc;
  --bs-table-striped-bg: #f2e3c2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d7b8;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecddbd;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6d7b8;
}

.table-danger {
  --bs-table-bg: #f8d4d4;
  --bs-table-striped-bg: #ecc9c9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfbfbf;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c4c4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfbfbf;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #090909;
  --bs-table-striped-bg: #151515;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #222222;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #1b1b1b;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #222222;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1599.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.09375rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.76563rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.75);
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: rgba(255, 255, 255, 0.75);
  background-color: #fff;
  border-color: #c4b8ad;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(137, 113, 91, 0.25);
}

.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: rgba(255, 255, 255, 0.75);
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}

.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: rgba(255, 255, 255, 0.75);
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.75);
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 0.2rem;
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.75);
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.form-select:focus {
  border-color: #c4b8ad;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(137, 113, 91, 0.25);
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: #e9ecef;
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 rgba(255, 255, 255, 0.75);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76563rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
}

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #c4b8ad;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(137, 113, 91, 0.25);
}

.form-check-input:checked {
  background-color: #89715b;
  border-color: #89715b;
}

.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #89715b;
  border-color: #89715b;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23c4b8ad'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(137, 113, 91, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(137, 113, 91, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #89715b;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #dcd4ce;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #89715b;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #dcd4ce;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}

.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem 0.75rem;
}

.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}

.form-floating > .form-control::placeholder {
  color: transparent;
}

.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #f58344;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  color: #000;
  background-color: rgba(245, 131, 68, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #f58344;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23f58344' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #f58344;
  box-shadow: 0 0 0 0.25rem rgba(245, 131, 68, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #f58344;
}

.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23f58344' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #f58344;
  box-shadow: 0 0 0 0.25rem rgba(245, 131, 68, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #f58344;
}

.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #f58344;
}

.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(245, 131, 68, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #f58344;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid, .was-validated
.input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}

.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus, .was-validated
.input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #da2929;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.76563rem;
  color: #fff;
  background-color: rgba(218, 41, 41, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #da2929;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23da2929'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23da2929' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #da2929;
  box-shadow: 0 0 0 0.25rem rgba(218, 41, 41, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #da2929;
}

.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23da2929'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23da2929' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #da2929;
  box-shadow: 0 0 0 0.25rem rgba(218, 41, 41, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #da2929;
}

.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #da2929;
}

.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 41, 41, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #da2929;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid, .was-validated
.input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}

.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus, .was-validated
.input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.75);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: rgba(255, 255, 255, 0.75);
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(137, 113, 91, 0.25);
}

.btn:disabled, .btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #89715b;
  border-color: #89715b;
}

.btn-primary:hover {
  color: #fff;
  background-color: #74604d;
  border-color: #6e5a49;
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #74604d;
  border-color: #6e5a49;
  box-shadow: 0 0 0 0.25rem rgba(155, 134, 116, 0.5);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #6e5a49;
  border-color: #675544;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(155, 134, 116, 0.5);
}

.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #89715b;
  border-color: #89715b;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}

.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}

.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #000;
  background-color: #f58344;
  border-color: #f58344;
}

.btn-success:hover {
  color: #000;
  background-color: #f79660;
  border-color: #f68f57;
}

.btn-check:focus + .btn-success, .btn-success:focus {
  color: #000;
  background-color: #f79660;
  border-color: #f68f57;
  box-shadow: 0 0 0 0.25rem rgba(208, 111, 58, 0.5);
}

.btn-check:checked + .btn-success,
.btn-check:active + .btn-success, .btn-success:active, .btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #000;
  background-color: #f79c69;
  border-color: #f68f57;
}

.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(208, 111, 58, 0.5);
}

.btn-success:disabled, .btn-success.disabled {
  color: #000;
  background-color: #f58344;
  border-color: #f58344;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}

.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-check:checked + .btn-info,
.btn-check:active + .btn-info, .btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}

.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}

.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #feae01;
  border-color: #feae01;
}

.btn-warning:hover {
  color: #000;
  background-color: #feba27;
  border-color: #feb61a;
}

.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #feba27;
  border-color: #feb61a;
  box-shadow: 0 0 0 0.25rem rgba(216, 148, 1, 0.5);
}

.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #febe34;
  border-color: #feb61a;
}

.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(216, 148, 1, 0.5);
}

.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #feae01;
  border-color: #feae01;
}

.btn-danger {
  color: #fff;
  background-color: #da2929;
  border-color: #da2929;
}

.btn-danger:hover {
  color: #fff;
  background-color: #b92323;
  border-color: #ae2121;
}

.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #b92323;
  border-color: #ae2121;
  box-shadow: 0 0 0 0.25rem rgba(224, 73, 73, 0.5);
}

.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ae2121;
  border-color: #a41f1f;
}

.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(224, 73, 73, 0.5);
}

.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #da2929;
  border-color: #da2929;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.btn-check:checked + .btn-light,
.btn-check:active + .btn-light, .btn-light:active, .btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}

.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}

.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #090909;
  border-color: #090909;
}

.btn-dark:hover {
  color: #fff;
  background-color: #080808;
  border-color: #070707;
}

.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #080808;
  border-color: #070707;
  box-shadow: 0 0 0 0.25rem rgba(46, 46, 46, 0.5);
}

.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #070707;
  border-color: #070707;
}

.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(46, 46, 46, 0.5);
}

.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #090909;
  border-color: #090909;
}

.btn-outline-primary {
  color: #89715b;
  border-color: #89715b;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #89715b;
  border-color: #89715b;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(137, 113, 91, 0.5);
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #89715b;
  border-color: #89715b;
}

.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(137, 113, 91, 0.5);
}

.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #89715b;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}

.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}

.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #f58344;
  border-color: #f58344;
}

.btn-outline-success:hover {
  color: #000;
  background-color: #f58344;
  border-color: #f58344;
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(245, 131, 68, 0.5);
}

.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #000;
  background-color: #f58344;
  border-color: #f58344;
}

.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(245, 131, 68, 0.5);
}

.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #f58344;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}

.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #feae01;
  border-color: #feae01;
}

.btn-outline-warning:hover {
  color: #000;
  background-color: #feae01;
  border-color: #feae01;
}

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(254, 174, 1, 0.5);
}

.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #feae01;
  border-color: #feae01;
}

.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(254, 174, 1, 0.5);
}

.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #feae01;
  background-color: transparent;
}

.btn-outline-danger {
  color: #da2929;
  border-color: #da2929;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #da2929;
  border-color: #da2929;
}

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 41, 41, 0.5);
}

.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #da2929;
  border-color: #da2929;
}

.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(218, 41, 41, 0.5);
}

.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #da2929;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #090909;
  border-color: #090909;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #090909;
  border-color: #090909;
}

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(9, 9, 9, 0.5);
}

.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #090909;
  border-color: #090909;
}

.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(9, 9, 9, 0.5);
}

.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #090909;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #89715b;
  text-decoration: underline;
}

.btn-link:hover {
  color: #6e5a49;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.75);
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto /* rtl:ignore */;
  left: 0 /* rtl:ignore */;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0 /* rtl:ignore */;
  left: auto /* rtl:ignore */;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

@media (min-width: 1600px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }
  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #89715b;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.76563rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #89715b;
}

.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}

.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}

.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}

.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #89715b;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover, .nav-link:focus {
  color: #6e5a49;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #89715b;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar > .container,
.navbar > .container-fluid, .navbar > .container-sm, .navbar > .container-md, .navbar > .container-lg, .navbar > .container-xl, .navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: 0.33594rem;
  padding-bottom: 0.33594rem;
  margin-right: 1rem;
  font-size: 1.09375rem;
  text-decoration: none;
  white-space: nowrap;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.09375rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (min-width: 1600px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  color: rgba(255, 255, 255, 0.75);
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: #7b6652;
  background-color: #f3f1ef;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237b6652'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}

.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%28255, 255, 255, 0.75%29'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #c4b8ad;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(137, 113, 91, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #89715b;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: #6e5a49;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  color: #6e5a49;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(137, 113, 91, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #89715b;
  border-color: #89715b;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.09375rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #524437;
  background-color: #e7e3de;
  border-color: #dcd4ce;
}

.alert-primary .alert-link {
  color: #42362c;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}

.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #934f29;
  background-color: #fde6da;
  border-color: #fcdac7;
}

.alert-success .alert-link {
  color: #763f21;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}

.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664600;
  background-color: #ffefcc;
  border-color: #ffe7b3;
}

.alert-warning .alert-link {
  color: #523800;
}

.alert-danger {
  color: #831919;
  background-color: #f8d4d4;
  border-color: #f4bfbf;
}

.alert-danger .alert-link {
  color: #691414;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}

.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #050505;
  background-color: #cecece;
  border-color: #b5b5b5;
}

.alert-dark .alert-link {
  color: #040404;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #89715b;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: rgba(255, 255, 255, 0.75);
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #89715b;
  border-color: #89715b;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1600px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #524437;
  background-color: #e7e3de;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #524437;
  background-color: #d0ccc8;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #524437;
  border-color: #524437;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #934f29;
  background-color: #fde6da;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #934f29;
  background-color: #e4cfc4;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #934f29;
  border-color: #934f29;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664600;
  background-color: #ffefcc;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664600;
  background-color: #e6d7b8;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664600;
  border-color: #664600;
}

.list-group-item-danger {
  color: #831919;
  background-color: #f8d4d4;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #831919;
  background-color: #dfbfbf;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #831919;
  border-color: #831919;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #050505;
  background-color: #cecece;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #050505;
  background-color: #b9b9b9;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #050505;
  border-color: #050505;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}

.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}

.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(137, 113, 91, 0.25);
  opacity: 1;
}

.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.toast:not(.showing):not(.show) {
  opacity: 0;
}

.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container > :not(:last-child) {
  margin-bottom: 15px;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1599.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76563rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: 0;
}

.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76563rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="top"] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="right"] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="bottom"] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}

.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^="left"] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: rgba(255, 255, 255, 0.75);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}

.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #89715b;
}

.link-primary:hover, .link-primary:focus {
  color: #6e5a49;
}

.link-secondary {
  color: #6c757d;
}

.link-secondary:hover, .link-secondary:focus {
  color: #565e64;
}

.link-success {
  color: #f58344;
}

.link-success:hover, .link-success:focus {
  color: #f79c69;
}

.link-info {
  color: #0dcaf0;
}

.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #feae01;
}

.link-warning:hover, .link-warning:focus {
  color: #febe34;
}

.link-danger {
  color: #da2929;
}

.link-danger:hover, .link-danger:focus {
  color: #ae2121;
}

.link-light {
  color: #f8f9fa;
}

.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #090909;
}

.link-dark:hover, .link-dark:focus {
  color: #070707;
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

@media (min-width: 1600px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #89715b !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #f58344 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #feae01 !important;
}

.border-danger {
  border-color: #da2929 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #090909 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.3125rem !important;
}

.gap-2 {
  gap: 0.625rem !important;
}

.gap-3 {
  gap: 0.9375rem !important;
}

.gap-4 {
  gap: 1.25rem !important;
}

.gap-5 {
  gap: 1.625rem !important;
}

.gap-6 {
  gap: 1.875rem !important;
}

.gap-7 {
  gap: 2.1875rem !important;
}

.gap-8 {
  gap: 2.5rem !important;
}

.gap-9 {
  gap: 2.8125rem !important;
}

.gap-10 {
  gap: 3.125rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.3125rem !important;
}

.m-2 {
  margin: 0.625rem !important;
}

.m-3 {
  margin: 0.9375rem !important;
}

.m-4 {
  margin: 1.25rem !important;
}

.m-5 {
  margin: 1.625rem !important;
}

.m-6 {
  margin: 1.875rem !important;
}

.m-7 {
  margin: 2.1875rem !important;
}

.m-8 {
  margin: 2.5rem !important;
}

.m-9 {
  margin: 2.8125rem !important;
}

.m-10 {
  margin: 3.125rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.3125rem !important;
  margin-left: 0.3125rem !important;
}

.mx-2 {
  margin-right: 0.625rem !important;
  margin-left: 0.625rem !important;
}

.mx-3 {
  margin-right: 0.9375rem !important;
  margin-left: 0.9375rem !important;
}

.mx-4 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-5 {
  margin-right: 1.625rem !important;
  margin-left: 1.625rem !important;
}

.mx-6 {
  margin-right: 1.875rem !important;
  margin-left: 1.875rem !important;
}

.mx-7 {
  margin-right: 2.1875rem !important;
  margin-left: 2.1875rem !important;
}

.mx-8 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-9 {
  margin-right: 2.8125rem !important;
  margin-left: 2.8125rem !important;
}

.mx-10 {
  margin-right: 3.125rem !important;
  margin-left: 3.125rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.3125rem !important;
  margin-bottom: 0.3125rem !important;
}

.my-2 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.my-3 {
  margin-top: 0.9375rem !important;
  margin-bottom: 0.9375rem !important;
}

.my-4 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-5 {
  margin-top: 1.625rem !important;
  margin-bottom: 1.625rem !important;
}

.my-6 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.my-7 {
  margin-top: 2.1875rem !important;
  margin-bottom: 2.1875rem !important;
}

.my-8 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-9 {
  margin-top: 2.8125rem !important;
  margin-bottom: 2.8125rem !important;
}

.my-10 {
  margin-top: 3.125rem !important;
  margin-bottom: 3.125rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.3125rem !important;
}

.mt-2 {
  margin-top: 0.625rem !important;
}

.mt-3 {
  margin-top: 0.9375rem !important;
}

.mt-4 {
  margin-top: 1.25rem !important;
}

.mt-5 {
  margin-top: 1.625rem !important;
}

.mt-6 {
  margin-top: 1.875rem !important;
}

.mt-7 {
  margin-top: 2.1875rem !important;
}

.mt-8 {
  margin-top: 2.5rem !important;
}

.mt-9 {
  margin-top: 2.8125rem !important;
}

.mt-10 {
  margin-top: 3.125rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.3125rem !important;
}

.me-2 {
  margin-right: 0.625rem !important;
}

.me-3 {
  margin-right: 0.9375rem !important;
}

.me-4 {
  margin-right: 1.25rem !important;
}

.me-5 {
  margin-right: 1.625rem !important;
}

.me-6 {
  margin-right: 1.875rem !important;
}

.me-7 {
  margin-right: 2.1875rem !important;
}

.me-8 {
  margin-right: 2.5rem !important;
}

.me-9 {
  margin-right: 2.8125rem !important;
}

.me-10 {
  margin-right: 3.125rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.3125rem !important;
}

.mb-2 {
  margin-bottom: 0.625rem !important;
}

.mb-3 {
  margin-bottom: 0.9375rem !important;
}

.mb-4 {
  margin-bottom: 1.25rem !important;
}

.mb-5 {
  margin-bottom: 1.625rem !important;
}

.mb-6 {
  margin-bottom: 1.875rem !important;
}

.mb-7 {
  margin-bottom: 2.1875rem !important;
}

.mb-8 {
  margin-bottom: 2.5rem !important;
}

.mb-9 {
  margin-bottom: 2.8125rem !important;
}

.mb-10 {
  margin-bottom: 3.125rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.3125rem !important;
}

.ms-2 {
  margin-left: 0.625rem !important;
}

.ms-3 {
  margin-left: 0.9375rem !important;
}

.ms-4 {
  margin-left: 1.25rem !important;
}

.ms-5 {
  margin-left: 1.625rem !important;
}

.ms-6 {
  margin-left: 1.875rem !important;
}

.ms-7 {
  margin-left: 2.1875rem !important;
}

.ms-8 {
  margin-left: 2.5rem !important;
}

.ms-9 {
  margin-left: 2.8125rem !important;
}

.ms-10 {
  margin-left: 3.125rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.3125rem !important;
}

.m-n2 {
  margin: -0.625rem !important;
}

.m-n3 {
  margin: -0.9375rem !important;
}

.m-n4 {
  margin: -1.25rem !important;
}

.m-n5 {
  margin: -1.625rem !important;
}

.m-n6 {
  margin: -1.875rem !important;
}

.m-n7 {
  margin: -2.1875rem !important;
}

.m-n8 {
  margin: -2.5rem !important;
}

.m-n9 {
  margin: -2.8125rem !important;
}

.m-n10 {
  margin: -3.125rem !important;
}

.mx-n1 {
  margin-right: -0.3125rem !important;
  margin-left: -0.3125rem !important;
}

.mx-n2 {
  margin-right: -0.625rem !important;
  margin-left: -0.625rem !important;
}

.mx-n3 {
  margin-right: -0.9375rem !important;
  margin-left: -0.9375rem !important;
}

.mx-n4 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n5 {
  margin-right: -1.625rem !important;
  margin-left: -1.625rem !important;
}

.mx-n6 {
  margin-right: -1.875rem !important;
  margin-left: -1.875rem !important;
}

.mx-n7 {
  margin-right: -2.1875rem !important;
  margin-left: -2.1875rem !important;
}

.mx-n8 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n9 {
  margin-right: -2.8125rem !important;
  margin-left: -2.8125rem !important;
}

.mx-n10 {
  margin-right: -3.125rem !important;
  margin-left: -3.125rem !important;
}

.my-n1 {
  margin-top: -0.3125rem !important;
  margin-bottom: -0.3125rem !important;
}

.my-n2 {
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important;
}

.my-n3 {
  margin-top: -0.9375rem !important;
  margin-bottom: -0.9375rem !important;
}

.my-n4 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n5 {
  margin-top: -1.625rem !important;
  margin-bottom: -1.625rem !important;
}

.my-n6 {
  margin-top: -1.875rem !important;
  margin-bottom: -1.875rem !important;
}

.my-n7 {
  margin-top: -2.1875rem !important;
  margin-bottom: -2.1875rem !important;
}

.my-n8 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n9 {
  margin-top: -2.8125rem !important;
  margin-bottom: -2.8125rem !important;
}

.my-n10 {
  margin-top: -3.125rem !important;
  margin-bottom: -3.125rem !important;
}

.mt-n1 {
  margin-top: -0.3125rem !important;
}

.mt-n2 {
  margin-top: -0.625rem !important;
}

.mt-n3 {
  margin-top: -0.9375rem !important;
}

.mt-n4 {
  margin-top: -1.25rem !important;
}

.mt-n5 {
  margin-top: -1.625rem !important;
}

.mt-n6 {
  margin-top: -1.875rem !important;
}

.mt-n7 {
  margin-top: -2.1875rem !important;
}

.mt-n8 {
  margin-top: -2.5rem !important;
}

.mt-n9 {
  margin-top: -2.8125rem !important;
}

.mt-n10 {
  margin-top: -3.125rem !important;
}

.me-n1 {
  margin-right: -0.3125rem !important;
}

.me-n2 {
  margin-right: -0.625rem !important;
}

.me-n3 {
  margin-right: -0.9375rem !important;
}

.me-n4 {
  margin-right: -1.25rem !important;
}

.me-n5 {
  margin-right: -1.625rem !important;
}

.me-n6 {
  margin-right: -1.875rem !important;
}

.me-n7 {
  margin-right: -2.1875rem !important;
}

.me-n8 {
  margin-right: -2.5rem !important;
}

.me-n9 {
  margin-right: -2.8125rem !important;
}

.me-n10 {
  margin-right: -3.125rem !important;
}

.mb-n1 {
  margin-bottom: -0.3125rem !important;
}

.mb-n2 {
  margin-bottom: -0.625rem !important;
}

.mb-n3 {
  margin-bottom: -0.9375rem !important;
}

.mb-n4 {
  margin-bottom: -1.25rem !important;
}

.mb-n5 {
  margin-bottom: -1.625rem !important;
}

.mb-n6 {
  margin-bottom: -1.875rem !important;
}

.mb-n7 {
  margin-bottom: -2.1875rem !important;
}

.mb-n8 {
  margin-bottom: -2.5rem !important;
}

.mb-n9 {
  margin-bottom: -2.8125rem !important;
}

.mb-n10 {
  margin-bottom: -3.125rem !important;
}

.ms-n1 {
  margin-left: -0.3125rem !important;
}

.ms-n2 {
  margin-left: -0.625rem !important;
}

.ms-n3 {
  margin-left: -0.9375rem !important;
}

.ms-n4 {
  margin-left: -1.25rem !important;
}

.ms-n5 {
  margin-left: -1.625rem !important;
}

.ms-n6 {
  margin-left: -1.875rem !important;
}

.ms-n7 {
  margin-left: -2.1875rem !important;
}

.ms-n8 {
  margin-left: -2.5rem !important;
}

.ms-n9 {
  margin-left: -2.8125rem !important;
}

.ms-n10 {
  margin-left: -3.125rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.3125rem !important;
}

.p-2 {
  padding: 0.625rem !important;
}

.p-3 {
  padding: 0.9375rem !important;
}

.p-4 {
  padding: 1.25rem !important;
}

.p-5 {
  padding: 1.625rem !important;
}

.p-6 {
  padding: 1.875rem !important;
}

.p-7 {
  padding: 2.1875rem !important;
}

.p-8 {
  padding: 2.5rem !important;
}

.p-9 {
  padding: 2.8125rem !important;
}

.p-10 {
  padding: 3.125rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.3125rem !important;
  padding-left: 0.3125rem !important;
}

.px-2 {
  padding-right: 0.625rem !important;
  padding-left: 0.625rem !important;
}

.px-3 {
  padding-right: 0.9375rem !important;
  padding-left: 0.9375rem !important;
}

.px-4 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-5 {
  padding-right: 1.625rem !important;
  padding-left: 1.625rem !important;
}

.px-6 {
  padding-right: 1.875rem !important;
  padding-left: 1.875rem !important;
}

.px-7 {
  padding-right: 2.1875rem !important;
  padding-left: 2.1875rem !important;
}

.px-8 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-9 {
  padding-right: 2.8125rem !important;
  padding-left: 2.8125rem !important;
}

.px-10 {
  padding-right: 3.125rem !important;
  padding-left: 3.125rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important;
}

.py-2 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.py-3 {
  padding-top: 0.9375rem !important;
  padding-bottom: 0.9375rem !important;
}

.py-4 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-5 {
  padding-top: 1.625rem !important;
  padding-bottom: 1.625rem !important;
}

.py-6 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.py-7 {
  padding-top: 2.1875rem !important;
  padding-bottom: 2.1875rem !important;
}

.py-8 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-9 {
  padding-top: 2.8125rem !important;
  padding-bottom: 2.8125rem !important;
}

.py-10 {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.3125rem !important;
}

.pt-2 {
  padding-top: 0.625rem !important;
}

.pt-3 {
  padding-top: 0.9375rem !important;
}

.pt-4 {
  padding-top: 1.25rem !important;
}

.pt-5 {
  padding-top: 1.625rem !important;
}

.pt-6 {
  padding-top: 1.875rem !important;
}

.pt-7 {
  padding-top: 2.1875rem !important;
}

.pt-8 {
  padding-top: 2.5rem !important;
}

.pt-9 {
  padding-top: 2.8125rem !important;
}

.pt-10 {
  padding-top: 3.125rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.3125rem !important;
}

.pe-2 {
  padding-right: 0.625rem !important;
}

.pe-3 {
  padding-right: 0.9375rem !important;
}

.pe-4 {
  padding-right: 1.25rem !important;
}

.pe-5 {
  padding-right: 1.625rem !important;
}

.pe-6 {
  padding-right: 1.875rem !important;
}

.pe-7 {
  padding-right: 2.1875rem !important;
}

.pe-8 {
  padding-right: 2.5rem !important;
}

.pe-9 {
  padding-right: 2.8125rem !important;
}

.pe-10 {
  padding-right: 3.125rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.3125rem !important;
}

.pb-2 {
  padding-bottom: 0.625rem !important;
}

.pb-3 {
  padding-bottom: 0.9375rem !important;
}

.pb-4 {
  padding-bottom: 1.25rem !important;
}

.pb-5 {
  padding-bottom: 1.625rem !important;
}

.pb-6 {
  padding-bottom: 1.875rem !important;
}

.pb-7 {
  padding-bottom: 2.1875rem !important;
}

.pb-8 {
  padding-bottom: 2.5rem !important;
}

.pb-9 {
  padding-bottom: 2.8125rem !important;
}

.pb-10 {
  padding-bottom: 3.125rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.3125rem !important;
}

.ps-2 {
  padding-left: 0.625rem !important;
}

.ps-3 {
  padding-left: 0.9375rem !important;
}

.ps-4 {
  padding-left: 1.25rem !important;
}

.ps-5 {
  padding-left: 1.625rem !important;
}

.ps-6 {
  padding-left: 1.875rem !important;
}

.ps-7 {
  padding-left: 2.1875rem !important;
}

.ps-8 {
  padding-left: 2.5rem !important;
}

.ps-9 {
  padding-left: 2.8125rem !important;
}

.ps-10 {
  padding-left: 3.125rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.34375rem + 1.125vw) !important;
}

.fs-2 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-3 {
  font-size: calc(1.27812rem + 0.3375vw) !important;
}

.fs-4 {
  font-size: calc(1.25625rem + 0.075vw) !important;
}

.fs-5 {
  font-size: 1.09375rem !important;
}

.fs-6 {
  font-size: 0.875rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #89715b !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-success {
  color: #f58344 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-warning {
  color: #feae01 !important;
}

.text-danger {
  color: #da2929 !important;
}

.text-light {
  color: #f8f9fa !important;
}

.text-dark {
  color: #090909 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: rgba(255, 255, 255, 0.75) !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #89715b !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #f58344 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: #feae01 !important;
}

.bg-danger {
  background-color: #da2929 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #090909 !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-1 {
    gap: 0.3125rem !important;
  }
  .gap-sm-2 {
    gap: 0.625rem !important;
  }
  .gap-sm-3 {
    gap: 0.9375rem !important;
  }
  .gap-sm-4 {
    gap: 1.25rem !important;
  }
  .gap-sm-5 {
    gap: 1.625rem !important;
  }
  .gap-sm-6 {
    gap: 1.875rem !important;
  }
  .gap-sm-7 {
    gap: 2.1875rem !important;
  }
  .gap-sm-8 {
    gap: 2.5rem !important;
  }
  .gap-sm-9 {
    gap: 2.8125rem !important;
  }
  .gap-sm-10 {
    gap: 3.125rem !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-1 {
    margin: 0.3125rem !important;
  }
  .m-sm-2 {
    margin: 0.625rem !important;
  }
  .m-sm-3 {
    margin: 0.9375rem !important;
  }
  .m-sm-4 {
    margin: 1.25rem !important;
  }
  .m-sm-5 {
    margin: 1.625rem !important;
  }
  .m-sm-6 {
    margin: 1.875rem !important;
  }
  .m-sm-7 {
    margin: 2.1875rem !important;
  }
  .m-sm-8 {
    margin: 2.5rem !important;
  }
  .m-sm-9 {
    margin: 2.8125rem !important;
  }
  .m-sm-10 {
    margin: 3.125rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-sm-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-sm-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-sm-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-sm-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-sm-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-sm-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-sm-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-sm-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-sm-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-sm-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-sm-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-sm-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-sm-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-sm-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-sm-2 {
    margin-top: 0.625rem !important;
  }
  .mt-sm-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-sm-4 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-5 {
    margin-top: 1.625rem !important;
  }
  .mt-sm-6 {
    margin-top: 1.875rem !important;
  }
  .mt-sm-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-sm-8 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-sm-10 {
    margin-top: 3.125rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-1 {
    margin-right: 0.3125rem !important;
  }
  .me-sm-2 {
    margin-right: 0.625rem !important;
  }
  .me-sm-3 {
    margin-right: 0.9375rem !important;
  }
  .me-sm-4 {
    margin-right: 1.25rem !important;
  }
  .me-sm-5 {
    margin-right: 1.625rem !important;
  }
  .me-sm-6 {
    margin-right: 1.875rem !important;
  }
  .me-sm-7 {
    margin-right: 2.1875rem !important;
  }
  .me-sm-8 {
    margin-right: 2.5rem !important;
  }
  .me-sm-9 {
    margin-right: 2.8125rem !important;
  }
  .me-sm-10 {
    margin-right: 3.125rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-sm-2 {
    margin-left: 0.625rem !important;
  }
  .ms-sm-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-sm-4 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-5 {
    margin-left: 1.625rem !important;
  }
  .ms-sm-6 {
    margin-left: 1.875rem !important;
  }
  .ms-sm-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-sm-8 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-sm-10 {
    margin-left: 3.125rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n1 {
    margin: -0.3125rem !important;
  }
  .m-sm-n2 {
    margin: -0.625rem !important;
  }
  .m-sm-n3 {
    margin: -0.9375rem !important;
  }
  .m-sm-n4 {
    margin: -1.25rem !important;
  }
  .m-sm-n5 {
    margin: -1.625rem !important;
  }
  .m-sm-n6 {
    margin: -1.875rem !important;
  }
  .m-sm-n7 {
    margin: -2.1875rem !important;
  }
  .m-sm-n8 {
    margin: -2.5rem !important;
  }
  .m-sm-n9 {
    margin: -2.8125rem !important;
  }
  .m-sm-n10 {
    margin: -3.125rem !important;
  }
  .mx-sm-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-sm-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-sm-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-sm-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-sm-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-sm-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-sm-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-sm-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-sm-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-sm-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-sm-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-sm-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-sm-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-sm-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-sm-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-sm-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-sm-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-sm-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-sm-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-sm-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-sm-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-sm-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-sm-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-sm-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-sm-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-sm-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-sm-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-sm-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-sm-n10 {
    margin-top: -3.125rem !important;
  }
  .me-sm-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-sm-n2 {
    margin-right: -0.625rem !important;
  }
  .me-sm-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-sm-n4 {
    margin-right: -1.25rem !important;
  }
  .me-sm-n5 {
    margin-right: -1.625rem !important;
  }
  .me-sm-n6 {
    margin-right: -1.875rem !important;
  }
  .me-sm-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-sm-n8 {
    margin-right: -2.5rem !important;
  }
  .me-sm-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-sm-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-sm-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-sm-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-sm-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-sm-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-sm-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-sm-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-sm-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-sm-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-sm-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-sm-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-sm-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-sm-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-sm-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-sm-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-sm-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-sm-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-sm-n10 {
    margin-left: -3.125rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-1 {
    padding: 0.3125rem !important;
  }
  .p-sm-2 {
    padding: 0.625rem !important;
  }
  .p-sm-3 {
    padding: 0.9375rem !important;
  }
  .p-sm-4 {
    padding: 1.25rem !important;
  }
  .p-sm-5 {
    padding: 1.625rem !important;
  }
  .p-sm-6 {
    padding: 1.875rem !important;
  }
  .p-sm-7 {
    padding: 2.1875rem !important;
  }
  .p-sm-8 {
    padding: 2.5rem !important;
  }
  .p-sm-9 {
    padding: 2.8125rem !important;
  }
  .p-sm-10 {
    padding: 3.125rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-sm-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-sm-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-sm-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-sm-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-sm-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-sm-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-sm-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-sm-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-sm-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-sm-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-sm-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-sm-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-sm-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-sm-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-sm-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-sm-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-sm-2 {
    padding-top: 0.625rem !important;
  }
  .pt-sm-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-sm-4 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-5 {
    padding-top: 1.625rem !important;
  }
  .pt-sm-6 {
    padding-top: 1.875rem !important;
  }
  .pt-sm-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-sm-8 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-sm-10 {
    padding-top: 3.125rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-sm-2 {
    padding-right: 0.625rem !important;
  }
  .pe-sm-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-sm-4 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-5 {
    padding-right: 1.625rem !important;
  }
  .pe-sm-6 {
    padding-right: 1.875rem !important;
  }
  .pe-sm-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-sm-8 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-sm-10 {
    padding-right: 3.125rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-sm-2 {
    padding-left: 0.625rem !important;
  }
  .ps-sm-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-sm-4 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-5 {
    padding-left: 1.625rem !important;
  }
  .ps-sm-6 {
    padding-left: 1.875rem !important;
  }
  .ps-sm-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-sm-8 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-sm-10 {
    padding-left: 3.125rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-1 {
    gap: 0.3125rem !important;
  }
  .gap-md-2 {
    gap: 0.625rem !important;
  }
  .gap-md-3 {
    gap: 0.9375rem !important;
  }
  .gap-md-4 {
    gap: 1.25rem !important;
  }
  .gap-md-5 {
    gap: 1.625rem !important;
  }
  .gap-md-6 {
    gap: 1.875rem !important;
  }
  .gap-md-7 {
    gap: 2.1875rem !important;
  }
  .gap-md-8 {
    gap: 2.5rem !important;
  }
  .gap-md-9 {
    gap: 2.8125rem !important;
  }
  .gap-md-10 {
    gap: 3.125rem !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-1 {
    margin: 0.3125rem !important;
  }
  .m-md-2 {
    margin: 0.625rem !important;
  }
  .m-md-3 {
    margin: 0.9375rem !important;
  }
  .m-md-4 {
    margin: 1.25rem !important;
  }
  .m-md-5 {
    margin: 1.625rem !important;
  }
  .m-md-6 {
    margin: 1.875rem !important;
  }
  .m-md-7 {
    margin: 2.1875rem !important;
  }
  .m-md-8 {
    margin: 2.5rem !important;
  }
  .m-md-9 {
    margin: 2.8125rem !important;
  }
  .m-md-10 {
    margin: 3.125rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-md-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-md-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-md-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-md-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-md-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-md-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-md-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-md-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-md-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-md-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-md-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-md-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-md-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-md-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-md-2 {
    margin-top: 0.625rem !important;
  }
  .mt-md-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-md-4 {
    margin-top: 1.25rem !important;
  }
  .mt-md-5 {
    margin-top: 1.625rem !important;
  }
  .mt-md-6 {
    margin-top: 1.875rem !important;
  }
  .mt-md-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-md-8 {
    margin-top: 2.5rem !important;
  }
  .mt-md-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-md-10 {
    margin-top: 3.125rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-1 {
    margin-right: 0.3125rem !important;
  }
  .me-md-2 {
    margin-right: 0.625rem !important;
  }
  .me-md-3 {
    margin-right: 0.9375rem !important;
  }
  .me-md-4 {
    margin-right: 1.25rem !important;
  }
  .me-md-5 {
    margin-right: 1.625rem !important;
  }
  .me-md-6 {
    margin-right: 1.875rem !important;
  }
  .me-md-7 {
    margin-right: 2.1875rem !important;
  }
  .me-md-8 {
    margin-right: 2.5rem !important;
  }
  .me-md-9 {
    margin-right: 2.8125rem !important;
  }
  .me-md-10 {
    margin-right: 3.125rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-md-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-md-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-md-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-md-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-md-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-md-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-md-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-md-2 {
    margin-left: 0.625rem !important;
  }
  .ms-md-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-md-4 {
    margin-left: 1.25rem !important;
  }
  .ms-md-5 {
    margin-left: 1.625rem !important;
  }
  .ms-md-6 {
    margin-left: 1.875rem !important;
  }
  .ms-md-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-md-8 {
    margin-left: 2.5rem !important;
  }
  .ms-md-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-md-10 {
    margin-left: 3.125rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n1 {
    margin: -0.3125rem !important;
  }
  .m-md-n2 {
    margin: -0.625rem !important;
  }
  .m-md-n3 {
    margin: -0.9375rem !important;
  }
  .m-md-n4 {
    margin: -1.25rem !important;
  }
  .m-md-n5 {
    margin: -1.625rem !important;
  }
  .m-md-n6 {
    margin: -1.875rem !important;
  }
  .m-md-n7 {
    margin: -2.1875rem !important;
  }
  .m-md-n8 {
    margin: -2.5rem !important;
  }
  .m-md-n9 {
    margin: -2.8125rem !important;
  }
  .m-md-n10 {
    margin: -3.125rem !important;
  }
  .mx-md-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-md-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-md-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-md-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-md-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-md-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-md-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-md-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-md-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-md-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-md-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-md-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-md-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-md-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-md-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-md-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-md-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-md-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-md-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-md-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-md-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-md-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-md-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-md-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-md-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-md-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-md-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-md-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-md-n10 {
    margin-top: -3.125rem !important;
  }
  .me-md-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-md-n2 {
    margin-right: -0.625rem !important;
  }
  .me-md-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-md-n4 {
    margin-right: -1.25rem !important;
  }
  .me-md-n5 {
    margin-right: -1.625rem !important;
  }
  .me-md-n6 {
    margin-right: -1.875rem !important;
  }
  .me-md-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-md-n8 {
    margin-right: -2.5rem !important;
  }
  .me-md-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-md-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-md-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-md-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-md-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-md-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-md-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-md-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-md-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-md-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-md-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-md-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-md-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-md-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-md-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-md-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-md-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-md-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-md-n10 {
    margin-left: -3.125rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-1 {
    padding: 0.3125rem !important;
  }
  .p-md-2 {
    padding: 0.625rem !important;
  }
  .p-md-3 {
    padding: 0.9375rem !important;
  }
  .p-md-4 {
    padding: 1.25rem !important;
  }
  .p-md-5 {
    padding: 1.625rem !important;
  }
  .p-md-6 {
    padding: 1.875rem !important;
  }
  .p-md-7 {
    padding: 2.1875rem !important;
  }
  .p-md-8 {
    padding: 2.5rem !important;
  }
  .p-md-9 {
    padding: 2.8125rem !important;
  }
  .p-md-10 {
    padding: 3.125rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-md-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-md-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-md-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-md-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-md-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-md-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-md-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-md-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-md-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-md-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-md-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-md-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-md-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-md-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-md-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-md-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-md-2 {
    padding-top: 0.625rem !important;
  }
  .pt-md-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-md-4 {
    padding-top: 1.25rem !important;
  }
  .pt-md-5 {
    padding-top: 1.625rem !important;
  }
  .pt-md-6 {
    padding-top: 1.875rem !important;
  }
  .pt-md-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-md-8 {
    padding-top: 2.5rem !important;
  }
  .pt-md-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-md-10 {
    padding-top: 3.125rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-md-2 {
    padding-right: 0.625rem !important;
  }
  .pe-md-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-md-4 {
    padding-right: 1.25rem !important;
  }
  .pe-md-5 {
    padding-right: 1.625rem !important;
  }
  .pe-md-6 {
    padding-right: 1.875rem !important;
  }
  .pe-md-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-md-8 {
    padding-right: 2.5rem !important;
  }
  .pe-md-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-md-10 {
    padding-right: 3.125rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-md-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-md-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-md-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-md-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-md-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-md-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-md-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-md-2 {
    padding-left: 0.625rem !important;
  }
  .ps-md-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-md-4 {
    padding-left: 1.25rem !important;
  }
  .ps-md-5 {
    padding-left: 1.625rem !important;
  }
  .ps-md-6 {
    padding-left: 1.875rem !important;
  }
  .ps-md-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-md-8 {
    padding-left: 2.5rem !important;
  }
  .ps-md-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-md-10 {
    padding-left: 3.125rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-1 {
    gap: 0.3125rem !important;
  }
  .gap-lg-2 {
    gap: 0.625rem !important;
  }
  .gap-lg-3 {
    gap: 0.9375rem !important;
  }
  .gap-lg-4 {
    gap: 1.25rem !important;
  }
  .gap-lg-5 {
    gap: 1.625rem !important;
  }
  .gap-lg-6 {
    gap: 1.875rem !important;
  }
  .gap-lg-7 {
    gap: 2.1875rem !important;
  }
  .gap-lg-8 {
    gap: 2.5rem !important;
  }
  .gap-lg-9 {
    gap: 2.8125rem !important;
  }
  .gap-lg-10 {
    gap: 3.125rem !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-1 {
    margin: 0.3125rem !important;
  }
  .m-lg-2 {
    margin: 0.625rem !important;
  }
  .m-lg-3 {
    margin: 0.9375rem !important;
  }
  .m-lg-4 {
    margin: 1.25rem !important;
  }
  .m-lg-5 {
    margin: 1.625rem !important;
  }
  .m-lg-6 {
    margin: 1.875rem !important;
  }
  .m-lg-7 {
    margin: 2.1875rem !important;
  }
  .m-lg-8 {
    margin: 2.5rem !important;
  }
  .m-lg-9 {
    margin: 2.8125rem !important;
  }
  .m-lg-10 {
    margin: 3.125rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-lg-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-lg-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-lg-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-lg-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-lg-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-lg-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-lg-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-lg-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-lg-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-lg-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-lg-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-lg-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-lg-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-lg-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-lg-2 {
    margin-top: 0.625rem !important;
  }
  .mt-lg-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-lg-4 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-5 {
    margin-top: 1.625rem !important;
  }
  .mt-lg-6 {
    margin-top: 1.875rem !important;
  }
  .mt-lg-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-lg-8 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-lg-10 {
    margin-top: 3.125rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-1 {
    margin-right: 0.3125rem !important;
  }
  .me-lg-2 {
    margin-right: 0.625rem !important;
  }
  .me-lg-3 {
    margin-right: 0.9375rem !important;
  }
  .me-lg-4 {
    margin-right: 1.25rem !important;
  }
  .me-lg-5 {
    margin-right: 1.625rem !important;
  }
  .me-lg-6 {
    margin-right: 1.875rem !important;
  }
  .me-lg-7 {
    margin-right: 2.1875rem !important;
  }
  .me-lg-8 {
    margin-right: 2.5rem !important;
  }
  .me-lg-9 {
    margin-right: 2.8125rem !important;
  }
  .me-lg-10 {
    margin-right: 3.125rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-lg-2 {
    margin-left: 0.625rem !important;
  }
  .ms-lg-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-lg-4 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-5 {
    margin-left: 1.625rem !important;
  }
  .ms-lg-6 {
    margin-left: 1.875rem !important;
  }
  .ms-lg-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-lg-8 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-lg-10 {
    margin-left: 3.125rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n1 {
    margin: -0.3125rem !important;
  }
  .m-lg-n2 {
    margin: -0.625rem !important;
  }
  .m-lg-n3 {
    margin: -0.9375rem !important;
  }
  .m-lg-n4 {
    margin: -1.25rem !important;
  }
  .m-lg-n5 {
    margin: -1.625rem !important;
  }
  .m-lg-n6 {
    margin: -1.875rem !important;
  }
  .m-lg-n7 {
    margin: -2.1875rem !important;
  }
  .m-lg-n8 {
    margin: -2.5rem !important;
  }
  .m-lg-n9 {
    margin: -2.8125rem !important;
  }
  .m-lg-n10 {
    margin: -3.125rem !important;
  }
  .mx-lg-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-lg-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-lg-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-lg-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-lg-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-lg-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-lg-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-lg-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-lg-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-lg-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-lg-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-lg-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-lg-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-lg-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-lg-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-lg-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-lg-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-lg-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-lg-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-lg-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-lg-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-lg-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-lg-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-lg-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-lg-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-lg-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-lg-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-lg-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-lg-n10 {
    margin-top: -3.125rem !important;
  }
  .me-lg-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-lg-n2 {
    margin-right: -0.625rem !important;
  }
  .me-lg-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-lg-n4 {
    margin-right: -1.25rem !important;
  }
  .me-lg-n5 {
    margin-right: -1.625rem !important;
  }
  .me-lg-n6 {
    margin-right: -1.875rem !important;
  }
  .me-lg-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-lg-n8 {
    margin-right: -2.5rem !important;
  }
  .me-lg-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-lg-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-lg-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-lg-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-lg-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-lg-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-lg-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-lg-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-lg-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-lg-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-lg-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-lg-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-lg-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-lg-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-lg-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-lg-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-lg-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-lg-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-lg-n10 {
    margin-left: -3.125rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-1 {
    padding: 0.3125rem !important;
  }
  .p-lg-2 {
    padding: 0.625rem !important;
  }
  .p-lg-3 {
    padding: 0.9375rem !important;
  }
  .p-lg-4 {
    padding: 1.25rem !important;
  }
  .p-lg-5 {
    padding: 1.625rem !important;
  }
  .p-lg-6 {
    padding: 1.875rem !important;
  }
  .p-lg-7 {
    padding: 2.1875rem !important;
  }
  .p-lg-8 {
    padding: 2.5rem !important;
  }
  .p-lg-9 {
    padding: 2.8125rem !important;
  }
  .p-lg-10 {
    padding: 3.125rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-lg-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-lg-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-lg-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-lg-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-lg-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-lg-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-lg-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-lg-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-lg-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-lg-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-lg-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-lg-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-lg-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-lg-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-lg-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-lg-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-lg-2 {
    padding-top: 0.625rem !important;
  }
  .pt-lg-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-lg-4 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-5 {
    padding-top: 1.625rem !important;
  }
  .pt-lg-6 {
    padding-top: 1.875rem !important;
  }
  .pt-lg-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-lg-8 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-lg-10 {
    padding-top: 3.125rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-lg-2 {
    padding-right: 0.625rem !important;
  }
  .pe-lg-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-lg-4 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-5 {
    padding-right: 1.625rem !important;
  }
  .pe-lg-6 {
    padding-right: 1.875rem !important;
  }
  .pe-lg-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-lg-8 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-lg-10 {
    padding-right: 3.125rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-lg-2 {
    padding-left: 0.625rem !important;
  }
  .ps-lg-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-lg-4 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-5 {
    padding-left: 1.625rem !important;
  }
  .ps-lg-6 {
    padding-left: 1.875rem !important;
  }
  .ps-lg-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-lg-8 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-lg-10 {
    padding-left: 3.125rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }
  .float-xl-end {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-grid {
    display: grid !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
  .d-xl-none {
    display: none !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xl-0 {
    gap: 0 !important;
  }
  .gap-xl-1 {
    gap: 0.3125rem !important;
  }
  .gap-xl-2 {
    gap: 0.625rem !important;
  }
  .gap-xl-3 {
    gap: 0.9375rem !important;
  }
  .gap-xl-4 {
    gap: 1.25rem !important;
  }
  .gap-xl-5 {
    gap: 1.625rem !important;
  }
  .gap-xl-6 {
    gap: 1.875rem !important;
  }
  .gap-xl-7 {
    gap: 2.1875rem !important;
  }
  .gap-xl-8 {
    gap: 2.5rem !important;
  }
  .gap-xl-9 {
    gap: 2.8125rem !important;
  }
  .gap-xl-10 {
    gap: 3.125rem !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
  .order-xl-first {
    order: -1 !important;
  }
  .order-xl-0 {
    order: 0 !important;
  }
  .order-xl-1 {
    order: 1 !important;
  }
  .order-xl-2 {
    order: 2 !important;
  }
  .order-xl-3 {
    order: 3 !important;
  }
  .order-xl-4 {
    order: 4 !important;
  }
  .order-xl-5 {
    order: 5 !important;
  }
  .order-xl-last {
    order: 6 !important;
  }
  .m-xl-0 {
    margin: 0 !important;
  }
  .m-xl-1 {
    margin: 0.3125rem !important;
  }
  .m-xl-2 {
    margin: 0.625rem !important;
  }
  .m-xl-3 {
    margin: 0.9375rem !important;
  }
  .m-xl-4 {
    margin: 1.25rem !important;
  }
  .m-xl-5 {
    margin: 1.625rem !important;
  }
  .m-xl-6 {
    margin: 1.875rem !important;
  }
  .m-xl-7 {
    margin: 2.1875rem !important;
  }
  .m-xl-8 {
    margin: 2.5rem !important;
  }
  .m-xl-9 {
    margin: 2.8125rem !important;
  }
  .m-xl-10 {
    margin: 3.125rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-xl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xl-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-xl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xl-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-xl-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xl-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-xl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xl-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-xl-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-xl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xl-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-xl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xl-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-xl-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xl-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-xl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-xl-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xl-0 {
    margin-top: 0 !important;
  }
  .mt-xl-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-xl-2 {
    margin-top: 0.625rem !important;
  }
  .mt-xl-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-xl-4 {
    margin-top: 1.25rem !important;
  }
  .mt-xl-5 {
    margin-top: 1.625rem !important;
  }
  .mt-xl-6 {
    margin-top: 1.875rem !important;
  }
  .mt-xl-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-xl-8 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-xl-10 {
    margin-top: 3.125rem !important;
  }
  .mt-xl-auto {
    margin-top: auto !important;
  }
  .me-xl-0 {
    margin-right: 0 !important;
  }
  .me-xl-1 {
    margin-right: 0.3125rem !important;
  }
  .me-xl-2 {
    margin-right: 0.625rem !important;
  }
  .me-xl-3 {
    margin-right: 0.9375rem !important;
  }
  .me-xl-4 {
    margin-right: 1.25rem !important;
  }
  .me-xl-5 {
    margin-right: 1.625rem !important;
  }
  .me-xl-6 {
    margin-right: 1.875rem !important;
  }
  .me-xl-7 {
    margin-right: 2.1875rem !important;
  }
  .me-xl-8 {
    margin-right: 2.5rem !important;
  }
  .me-xl-9 {
    margin-right: 2.8125rem !important;
  }
  .me-xl-10 {
    margin-right: 3.125rem !important;
  }
  .me-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-xl-auto {
    margin-bottom: auto !important;
  }
  .ms-xl-0 {
    margin-left: 0 !important;
  }
  .ms-xl-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-xl-2 {
    margin-left: 0.625rem !important;
  }
  .ms-xl-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-xl-4 {
    margin-left: 1.25rem !important;
  }
  .ms-xl-5 {
    margin-left: 1.625rem !important;
  }
  .ms-xl-6 {
    margin-left: 1.875rem !important;
  }
  .ms-xl-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-xl-8 {
    margin-left: 2.5rem !important;
  }
  .ms-xl-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-xl-10 {
    margin-left: 3.125rem !important;
  }
  .ms-xl-auto {
    margin-left: auto !important;
  }
  .m-xl-n1 {
    margin: -0.3125rem !important;
  }
  .m-xl-n2 {
    margin: -0.625rem !important;
  }
  .m-xl-n3 {
    margin: -0.9375rem !important;
  }
  .m-xl-n4 {
    margin: -1.25rem !important;
  }
  .m-xl-n5 {
    margin: -1.625rem !important;
  }
  .m-xl-n6 {
    margin: -1.875rem !important;
  }
  .m-xl-n7 {
    margin: -2.1875rem !important;
  }
  .m-xl-n8 {
    margin: -2.5rem !important;
  }
  .m-xl-n9 {
    margin: -2.8125rem !important;
  }
  .m-xl-n10 {
    margin: -3.125rem !important;
  }
  .mx-xl-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-xl-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-xl-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-xl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xl-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-xl-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-xl-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-xl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xl-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-xl-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-xl-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-xl-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-xl-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-xl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xl-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-xl-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-xl-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-xl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xl-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-xl-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-xl-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-xl-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-xl-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-xl-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-xl-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-xl-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-xl-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-xl-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-xl-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-xl-n10 {
    margin-top: -3.125rem !important;
  }
  .me-xl-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-xl-n2 {
    margin-right: -0.625rem !important;
  }
  .me-xl-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-xl-n4 {
    margin-right: -1.25rem !important;
  }
  .me-xl-n5 {
    margin-right: -1.625rem !important;
  }
  .me-xl-n6 {
    margin-right: -1.875rem !important;
  }
  .me-xl-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-xl-n8 {
    margin-right: -2.5rem !important;
  }
  .me-xl-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-xl-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-xl-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-xl-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-xl-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-xl-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xl-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-xl-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-xl-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-xl-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xl-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-xl-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-xl-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-xl-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-xl-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-xl-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-xl-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-xl-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-xl-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-xl-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-xl-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-xl-n10 {
    margin-left: -3.125rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .p-xl-1 {
    padding: 0.3125rem !important;
  }
  .p-xl-2 {
    padding: 0.625rem !important;
  }
  .p-xl-3 {
    padding: 0.9375rem !important;
  }
  .p-xl-4 {
    padding: 1.25rem !important;
  }
  .p-xl-5 {
    padding: 1.625rem !important;
  }
  .p-xl-6 {
    padding: 1.875rem !important;
  }
  .p-xl-7 {
    padding: 2.1875rem !important;
  }
  .p-xl-8 {
    padding: 2.5rem !important;
  }
  .p-xl-9 {
    padding: 2.8125rem !important;
  }
  .p-xl-10 {
    padding: 3.125rem !important;
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-xl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xl-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-xl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xl-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-xl-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xl-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-xl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xl-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-xl-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-xl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xl-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-xl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xl-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-xl-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xl-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-xl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-xl-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-xl-0 {
    padding-top: 0 !important;
  }
  .pt-xl-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-xl-2 {
    padding-top: 0.625rem !important;
  }
  .pt-xl-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-xl-4 {
    padding-top: 1.25rem !important;
  }
  .pt-xl-5 {
    padding-top: 1.625rem !important;
  }
  .pt-xl-6 {
    padding-top: 1.875rem !important;
  }
  .pt-xl-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-xl-8 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-xl-10 {
    padding-top: 3.125rem !important;
  }
  .pe-xl-0 {
    padding-right: 0 !important;
  }
  .pe-xl-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-xl-2 {
    padding-right: 0.625rem !important;
  }
  .pe-xl-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-xl-4 {
    padding-right: 1.25rem !important;
  }
  .pe-xl-5 {
    padding-right: 1.625rem !important;
  }
  .pe-xl-6 {
    padding-right: 1.875rem !important;
  }
  .pe-xl-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-xl-8 {
    padding-right: 2.5rem !important;
  }
  .pe-xl-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-xl-10 {
    padding-right: 3.125rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-xl-0 {
    padding-left: 0 !important;
  }
  .ps-xl-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-xl-2 {
    padding-left: 0.625rem !important;
  }
  .ps-xl-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-xl-4 {
    padding-left: 1.25rem !important;
  }
  .ps-xl-5 {
    padding-left: 1.625rem !important;
  }
  .ps-xl-6 {
    padding-left: 1.875rem !important;
  }
  .ps-xl-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-xl-8 {
    padding-left: 2.5rem !important;
  }
  .ps-xl-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-xl-10 {
    padding-left: 3.125rem !important;
  }
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1600px) {
  .float-xxl-start {
    float: left !important;
  }
  .float-xxl-end {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-grid {
    display: grid !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .d-xxl-none {
    display: none !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .gap-xxl-0 {
    gap: 0 !important;
  }
  .gap-xxl-1 {
    gap: 0.3125rem !important;
  }
  .gap-xxl-2 {
    gap: 0.625rem !important;
  }
  .gap-xxl-3 {
    gap: 0.9375rem !important;
  }
  .gap-xxl-4 {
    gap: 1.25rem !important;
  }
  .gap-xxl-5 {
    gap: 1.625rem !important;
  }
  .gap-xxl-6 {
    gap: 1.875rem !important;
  }
  .gap-xxl-7 {
    gap: 2.1875rem !important;
  }
  .gap-xxl-8 {
    gap: 2.5rem !important;
  }
  .gap-xxl-9 {
    gap: 2.8125rem !important;
  }
  .gap-xxl-10 {
    gap: 3.125rem !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
  .order-xxl-first {
    order: -1 !important;
  }
  .order-xxl-0 {
    order: 0 !important;
  }
  .order-xxl-1 {
    order: 1 !important;
  }
  .order-xxl-2 {
    order: 2 !important;
  }
  .order-xxl-3 {
    order: 3 !important;
  }
  .order-xxl-4 {
    order: 4 !important;
  }
  .order-xxl-5 {
    order: 5 !important;
  }
  .order-xxl-last {
    order: 6 !important;
  }
  .m-xxl-0 {
    margin: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.3125rem !important;
  }
  .m-xxl-2 {
    margin: 0.625rem !important;
  }
  .m-xxl-3 {
    margin: 0.9375rem !important;
  }
  .m-xxl-4 {
    margin: 1.25rem !important;
  }
  .m-xxl-5 {
    margin: 1.625rem !important;
  }
  .m-xxl-6 {
    margin: 1.875rem !important;
  }
  .m-xxl-7 {
    margin: 2.1875rem !important;
  }
  .m-xxl-8 {
    margin: 2.5rem !important;
  }
  .m-xxl-9 {
    margin: 2.8125rem !important;
  }
  .m-xxl-10 {
    margin: 3.125rem !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-xxl-1 {
    margin-right: 0.3125rem !important;
    margin-left: 0.3125rem !important;
  }
  .mx-xxl-2 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-xxl-3 {
    margin-right: 0.9375rem !important;
    margin-left: 0.9375rem !important;
  }
  .mx-xxl-4 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-xxl-5 {
    margin-right: 1.625rem !important;
    margin-left: 1.625rem !important;
  }
  .mx-xxl-6 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-xxl-7 {
    margin-right: 2.1875rem !important;
    margin-left: 2.1875rem !important;
  }
  .mx-xxl-8 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-xxl-9 {
    margin-right: 2.8125rem !important;
    margin-left: 2.8125rem !important;
  }
  .mx-xxl-10 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-xxl-1 {
    margin-top: 0.3125rem !important;
    margin-bottom: 0.3125rem !important;
  }
  .my-xxl-2 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-xxl-3 {
    margin-top: 0.9375rem !important;
    margin-bottom: 0.9375rem !important;
  }
  .my-xxl-4 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-xxl-5 {
    margin-top: 1.625rem !important;
    margin-bottom: 1.625rem !important;
  }
  .my-xxl-6 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-xxl-7 {
    margin-top: 2.1875rem !important;
    margin-bottom: 2.1875rem !important;
  }
  .my-xxl-8 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xxl-9 {
    margin-top: 2.8125rem !important;
    margin-bottom: 2.8125rem !important;
  }
  .my-xxl-10 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-xxl-0 {
    margin-top: 0 !important;
  }
  .mt-xxl-1 {
    margin-top: 0.3125rem !important;
  }
  .mt-xxl-2 {
    margin-top: 0.625rem !important;
  }
  .mt-xxl-3 {
    margin-top: 0.9375rem !important;
  }
  .mt-xxl-4 {
    margin-top: 1.25rem !important;
  }
  .mt-xxl-5 {
    margin-top: 1.625rem !important;
  }
  .mt-xxl-6 {
    margin-top: 1.875rem !important;
  }
  .mt-xxl-7 {
    margin-top: 2.1875rem !important;
  }
  .mt-xxl-8 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-9 {
    margin-top: 2.8125rem !important;
  }
  .mt-xxl-10 {
    margin-top: 3.125rem !important;
  }
  .mt-xxl-auto {
    margin-top: auto !important;
  }
  .me-xxl-0 {
    margin-right: 0 !important;
  }
  .me-xxl-1 {
    margin-right: 0.3125rem !important;
  }
  .me-xxl-2 {
    margin-right: 0.625rem !important;
  }
  .me-xxl-3 {
    margin-right: 0.9375rem !important;
  }
  .me-xxl-4 {
    margin-right: 1.25rem !important;
  }
  .me-xxl-5 {
    margin-right: 1.625rem !important;
  }
  .me-xxl-6 {
    margin-right: 1.875rem !important;
  }
  .me-xxl-7 {
    margin-right: 2.1875rem !important;
  }
  .me-xxl-8 {
    margin-right: 2.5rem !important;
  }
  .me-xxl-9 {
    margin-right: 2.8125rem !important;
  }
  .me-xxl-10 {
    margin-right: 3.125rem !important;
  }
  .me-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.3125rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 0.625rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 0.9375rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 1.25rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 1.625rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 1.875rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 2.1875rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 2.8125rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 3.125rem !important;
  }
  .mb-xxl-auto {
    margin-bottom: auto !important;
  }
  .ms-xxl-0 {
    margin-left: 0 !important;
  }
  .ms-xxl-1 {
    margin-left: 0.3125rem !important;
  }
  .ms-xxl-2 {
    margin-left: 0.625rem !important;
  }
  .ms-xxl-3 {
    margin-left: 0.9375rem !important;
  }
  .ms-xxl-4 {
    margin-left: 1.25rem !important;
  }
  .ms-xxl-5 {
    margin-left: 1.625rem !important;
  }
  .ms-xxl-6 {
    margin-left: 1.875rem !important;
  }
  .ms-xxl-7 {
    margin-left: 2.1875rem !important;
  }
  .ms-xxl-8 {
    margin-left: 2.5rem !important;
  }
  .ms-xxl-9 {
    margin-left: 2.8125rem !important;
  }
  .ms-xxl-10 {
    margin-left: 3.125rem !important;
  }
  .ms-xxl-auto {
    margin-left: auto !important;
  }
  .m-xxl-n1 {
    margin: -0.3125rem !important;
  }
  .m-xxl-n2 {
    margin: -0.625rem !important;
  }
  .m-xxl-n3 {
    margin: -0.9375rem !important;
  }
  .m-xxl-n4 {
    margin: -1.25rem !important;
  }
  .m-xxl-n5 {
    margin: -1.625rem !important;
  }
  .m-xxl-n6 {
    margin: -1.875rem !important;
  }
  .m-xxl-n7 {
    margin: -2.1875rem !important;
  }
  .m-xxl-n8 {
    margin: -2.5rem !important;
  }
  .m-xxl-n9 {
    margin: -2.8125rem !important;
  }
  .m-xxl-n10 {
    margin: -3.125rem !important;
  }
  .mx-xxl-n1 {
    margin-right: -0.3125rem !important;
    margin-left: -0.3125rem !important;
  }
  .mx-xxl-n2 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-xxl-n3 {
    margin-right: -0.9375rem !important;
    margin-left: -0.9375rem !important;
  }
  .mx-xxl-n4 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-xxl-n5 {
    margin-right: -1.625rem !important;
    margin-left: -1.625rem !important;
  }
  .mx-xxl-n6 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-xxl-n7 {
    margin-right: -2.1875rem !important;
    margin-left: -2.1875rem !important;
  }
  .mx-xxl-n8 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-xxl-n9 {
    margin-right: -2.8125rem !important;
    margin-left: -2.8125rem !important;
  }
  .mx-xxl-n10 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .my-xxl-n1 {
    margin-top: -0.3125rem !important;
    margin-bottom: -0.3125rem !important;
  }
  .my-xxl-n2 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-xxl-n3 {
    margin-top: -0.9375rem !important;
    margin-bottom: -0.9375rem !important;
  }
  .my-xxl-n4 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-xxl-n5 {
    margin-top: -1.625rem !important;
    margin-bottom: -1.625rem !important;
  }
  .my-xxl-n6 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-xxl-n7 {
    margin-top: -2.1875rem !important;
    margin-bottom: -2.1875rem !important;
  }
  .my-xxl-n8 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-xxl-n9 {
    margin-top: -2.8125rem !important;
    margin-bottom: -2.8125rem !important;
  }
  .my-xxl-n10 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .mt-xxl-n1 {
    margin-top: -0.3125rem !important;
  }
  .mt-xxl-n2 {
    margin-top: -0.625rem !important;
  }
  .mt-xxl-n3 {
    margin-top: -0.9375rem !important;
  }
  .mt-xxl-n4 {
    margin-top: -1.25rem !important;
  }
  .mt-xxl-n5 {
    margin-top: -1.625rem !important;
  }
  .mt-xxl-n6 {
    margin-top: -1.875rem !important;
  }
  .mt-xxl-n7 {
    margin-top: -2.1875rem !important;
  }
  .mt-xxl-n8 {
    margin-top: -2.5rem !important;
  }
  .mt-xxl-n9 {
    margin-top: -2.8125rem !important;
  }
  .mt-xxl-n10 {
    margin-top: -3.125rem !important;
  }
  .me-xxl-n1 {
    margin-right: -0.3125rem !important;
  }
  .me-xxl-n2 {
    margin-right: -0.625rem !important;
  }
  .me-xxl-n3 {
    margin-right: -0.9375rem !important;
  }
  .me-xxl-n4 {
    margin-right: -1.25rem !important;
  }
  .me-xxl-n5 {
    margin-right: -1.625rem !important;
  }
  .me-xxl-n6 {
    margin-right: -1.875rem !important;
  }
  .me-xxl-n7 {
    margin-right: -2.1875rem !important;
  }
  .me-xxl-n8 {
    margin-right: -2.5rem !important;
  }
  .me-xxl-n9 {
    margin-right: -2.8125rem !important;
  }
  .me-xxl-n10 {
    margin-right: -3.125rem !important;
  }
  .mb-xxl-n1 {
    margin-bottom: -0.3125rem !important;
  }
  .mb-xxl-n2 {
    margin-bottom: -0.625rem !important;
  }
  .mb-xxl-n3 {
    margin-bottom: -0.9375rem !important;
  }
  .mb-xxl-n4 {
    margin-bottom: -1.25rem !important;
  }
  .mb-xxl-n5 {
    margin-bottom: -1.625rem !important;
  }
  .mb-xxl-n6 {
    margin-bottom: -1.875rem !important;
  }
  .mb-xxl-n7 {
    margin-bottom: -2.1875rem !important;
  }
  .mb-xxl-n8 {
    margin-bottom: -2.5rem !important;
  }
  .mb-xxl-n9 {
    margin-bottom: -2.8125rem !important;
  }
  .mb-xxl-n10 {
    margin-bottom: -3.125rem !important;
  }
  .ms-xxl-n1 {
    margin-left: -0.3125rem !important;
  }
  .ms-xxl-n2 {
    margin-left: -0.625rem !important;
  }
  .ms-xxl-n3 {
    margin-left: -0.9375rem !important;
  }
  .ms-xxl-n4 {
    margin-left: -1.25rem !important;
  }
  .ms-xxl-n5 {
    margin-left: -1.625rem !important;
  }
  .ms-xxl-n6 {
    margin-left: -1.875rem !important;
  }
  .ms-xxl-n7 {
    margin-left: -2.1875rem !important;
  }
  .ms-xxl-n8 {
    margin-left: -2.5rem !important;
  }
  .ms-xxl-n9 {
    margin-left: -2.8125rem !important;
  }
  .ms-xxl-n10 {
    margin-left: -3.125rem !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.3125rem !important;
  }
  .p-xxl-2 {
    padding: 0.625rem !important;
  }
  .p-xxl-3 {
    padding: 0.9375rem !important;
  }
  .p-xxl-4 {
    padding: 1.25rem !important;
  }
  .p-xxl-5 {
    padding: 1.625rem !important;
  }
  .p-xxl-6 {
    padding: 1.875rem !important;
  }
  .p-xxl-7 {
    padding: 2.1875rem !important;
  }
  .p-xxl-8 {
    padding: 2.5rem !important;
  }
  .p-xxl-9 {
    padding: 2.8125rem !important;
  }
  .p-xxl-10 {
    padding: 3.125rem !important;
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-xxl-1 {
    padding-right: 0.3125rem !important;
    padding-left: 0.3125rem !important;
  }
  .px-xxl-2 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-xxl-3 {
    padding-right: 0.9375rem !important;
    padding-left: 0.9375rem !important;
  }
  .px-xxl-4 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-xxl-5 {
    padding-right: 1.625rem !important;
    padding-left: 1.625rem !important;
  }
  .px-xxl-6 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-xxl-7 {
    padding-right: 2.1875rem !important;
    padding-left: 2.1875rem !important;
  }
  .px-xxl-8 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-xxl-9 {
    padding-right: 2.8125rem !important;
    padding-left: 2.8125rem !important;
  }
  .px-xxl-10 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-xxl-1 {
    padding-top: 0.3125rem !important;
    padding-bottom: 0.3125rem !important;
  }
  .py-xxl-2 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-xxl-3 {
    padding-top: 0.9375rem !important;
    padding-bottom: 0.9375rem !important;
  }
  .py-xxl-4 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-xxl-5 {
    padding-top: 1.625rem !important;
    padding-bottom: 1.625rem !important;
  }
  .py-xxl-6 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-xxl-7 {
    padding-top: 2.1875rem !important;
    padding-bottom: 2.1875rem !important;
  }
  .py-xxl-8 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xxl-9 {
    padding-top: 2.8125rem !important;
    padding-bottom: 2.8125rem !important;
  }
  .py-xxl-10 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0 !important;
  }
  .pt-xxl-1 {
    padding-top: 0.3125rem !important;
  }
  .pt-xxl-2 {
    padding-top: 0.625rem !important;
  }
  .pt-xxl-3 {
    padding-top: 0.9375rem !important;
  }
  .pt-xxl-4 {
    padding-top: 1.25rem !important;
  }
  .pt-xxl-5 {
    padding-top: 1.625rem !important;
  }
  .pt-xxl-6 {
    padding-top: 1.875rem !important;
  }
  .pt-xxl-7 {
    padding-top: 2.1875rem !important;
  }
  .pt-xxl-8 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-9 {
    padding-top: 2.8125rem !important;
  }
  .pt-xxl-10 {
    padding-top: 3.125rem !important;
  }
  .pe-xxl-0 {
    padding-right: 0 !important;
  }
  .pe-xxl-1 {
    padding-right: 0.3125rem !important;
  }
  .pe-xxl-2 {
    padding-right: 0.625rem !important;
  }
  .pe-xxl-3 {
    padding-right: 0.9375rem !important;
  }
  .pe-xxl-4 {
    padding-right: 1.25rem !important;
  }
  .pe-xxl-5 {
    padding-right: 1.625rem !important;
  }
  .pe-xxl-6 {
    padding-right: 1.875rem !important;
  }
  .pe-xxl-7 {
    padding-right: 2.1875rem !important;
  }
  .pe-xxl-8 {
    padding-right: 2.5rem !important;
  }
  .pe-xxl-9 {
    padding-right: 2.8125rem !important;
  }
  .pe-xxl-10 {
    padding-right: 3.125rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.3125rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 0.625rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 0.9375rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 1.25rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 1.625rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 1.875rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 2.1875rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 2.8125rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 3.125rem !important;
  }
  .ps-xxl-0 {
    padding-left: 0 !important;
  }
  .ps-xxl-1 {
    padding-left: 0.3125rem !important;
  }
  .ps-xxl-2 {
    padding-left: 0.625rem !important;
  }
  .ps-xxl-3 {
    padding-left: 0.9375rem !important;
  }
  .ps-xxl-4 {
    padding-left: 1.25rem !important;
  }
  .ps-xxl-5 {
    padding-left: 1.625rem !important;
  }
  .ps-xxl-6 {
    padding-left: 1.875rem !important;
  }
  .ps-xxl-7 {
    padding-left: 2.1875rem !important;
  }
  .ps-xxl-8 {
    padding-left: 2.5rem !important;
  }
  .ps-xxl-9 {
    padding-left: 2.8125rem !important;
  }
  .ps-xxl-10 {
    padding-left: 3.125rem !important;
  }
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.1875rem !important;
  }
  .fs-2 {
    font-size: 1.75rem !important;
  }
  .fs-3 {
    font-size: 1.53125rem !important;
  }
  .fs-4 {
    font-size: 1.3125rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}

/*----------------------------------------*/
/*  01. Template default CSS
/*----------------------------------------*/
/*-- Google Font --*/
/*-- Common Style --*/
*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  position: relative;
  outline: none;
  visibility: visible;
  line-height: 1.714;
  overflow-X: hidden;
  color: "";
}

body[dir="rtl"] {
  text-align: right;
}

a,
button {
  transition: all 0.3s ease 0s;
  outline: 0;
}

a:focus,
button:focus {
  outline: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

p:last-child {
  margin-bottom: 0;
}

ul, ol {
  padding: 0;
  list-style: none;
  margin: 0;
}

img {
  max-width: 100%;
}

.main-wrapper {
  background-color: #141414;
}

@media only screen and (min-width: 1600px) {
  .custom-container {
    max-width: 1920px;
    padding: 0 70px;
  }
}

/* Section Style */
.section,
.main-wrapper {
  float: left;
  width: 100%;
}

/* Section Padding */
.section-padding {
  padding-top: 160px;
  padding-bottom: 160px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .section-padding {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.section-padding-02 {
  padding-top: 160px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .section-padding-02 {
    padding-top: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-padding-02 {
    padding-top: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding-02 {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .section-padding-02 {
    padding-top: 60px;
  }
}

.section-title .title {
  font-size: 52px;
  font-weight: 700;
  color: #89715b;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .section-title .title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .section-title .title {
    font-size: 28px;
  }
}

.section-title-2 .title {
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter", sans-serif;
  color: rgba(255, 255, 255, 0.75);
  text-transform: uppercase;
}

.tab-content .tab-pane {
  display: block;
  overflow: hidden;
  height: 0;
  visibility: hidden;
  max-width: 100%;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
  animation: fadeInUp 0.8s;
}

.page-banner-title {
  padding-top: 247px;
  position: relative;
  z-index: 3;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .page-banner-title {
    padding-top: 187px;
  }
}

@media only screen and (max-width: 575px) {
  .page-banner-title {
    padding-top: 147px;
  }
}

.page-banner-title .title {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
}

.page-banner-title .title span {
  color: #89715b;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .page-banner-title .title {
    font-size: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .page-banner-title .title {
    font-size: 42px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .page-banner-title .title {
    font-size: 38px;
  }
}

@media only screen and (max-width: 575px) {
  .page-banner-title .title {
    font-size: 30px;
  }
  .page-banner-title .title br {
    display: none;
  }
}

.page-banner-title p {
  margin-bottom: 0;
  margin-top: 25px;
  text-transform: uppercase;
  font-size: 16px;
}

.page-banner-title .meta {
  display: flex;
  margin-top: 25px;
}

.page-banner-title .meta li {
  position: relative;
}

.page-banner-title .meta li + li::before {
  content: '/';
  color: #333333;
  margin: 0 15px;
  font-size: 13px;
}

.page-banner-title .meta li a {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

.page-banner-title .meta li a:hover {
  color: #f89200;
}

.filter-menu {
  padding-top: 85px;
}

.filter-menu .menu-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -25px;
  margin-right: -25px;
}

@media only screen and (max-width: 767px) {
  .filter-menu .menu-list {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.filter-menu .menu-list li {
  padding: 0 25px;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .filter-menu .menu-list li {
    padding: 0 15px;
  }
}

.filter-menu .menu-list li.active {
  color: #89715b;
}

.load-more {
  margin-top: -10px;
  margin-bottom: -10px;
}

.load-more .more {
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: #666666;
  letter-spacing: 10px;
  text-transform: uppercase;
}

.load-more .more:hover {
  color: #89715b;
}

/*----------------------------------------*/
/*  02. Component CSS
/*----------------------------------------*/
/*--
/*  2.1 - Button CSS	
/*----------------------------------------*/
.btn:focus {
  box-shadow: none;
}

.btn-hover-primary:hover {
  border-color: #89715b;
  background-color: #89715b;
  color: #fff;
}

.btn-hover-secondary:hover {
  border-color: #6c757d;
  background-color: #6c757d;
  color: #fff;
}

.btn-hover-success:hover {
  border-color: #f58344;
  background-color: #f58344;
  color: #fff;
}

.btn-hover-info:hover {
  border-color: #0dcaf0;
  background-color: #0dcaf0;
  color: #fff;
}

.btn-hover-warning:hover {
  border-color: #feae01;
  background-color: #feae01;
  color: #090909;
}

.btn-hover-danger:hover {
  border-color: #da2929;
  background-color: #da2929;
  color: #fff;
}

.btn-hover-light:hover {
  border-color: #f8f9fa;
  background-color: #f8f9fa;
  color: #090909;
}

.btn-hover-dark:hover {
  border-color: #090909;
  background-color: #090909;
  color: #fff;
}

/*--
/*  2.2 - Form CSS	
/*----------------------------------------*/
.single-form {
  margin-top: 30px;
}

.single-form input,
.single-form textarea {
  width: 100%;
  border: 0;
  background: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  padding: 15px 0;
  outline: none;
  transition: all 0.3s ease 0s;
}

.single-form input::-webkit-input-placeholder,
.single-form textarea::-webkit-input-placeholder {
  opacity: 1;
}

.single-form input:-moz-placeholder,
.single-form textarea:-moz-placeholder {
  opacity: 1;
}

.single-form input::-moz-placeholder,
.single-form textarea::-moz-placeholder {
  opacity: 1;
}

.single-form input:-ms-input-placeholder,
.single-form textarea:-ms-input-placeholder {
  opacity: 1;
}

.single-form input:focus,
.single-form textarea:focus {
  border-color: #fff;
}

.single-form textarea {
  height: 150px;
  resize: none;
}

.form-btn {
  padding-top: 30px;
}

.form-btn button {
  font-size: 20px;
  font-weight: 700;
  font-family: "Zilla Slab", serif;
  color: #fff;
  text-transform: uppercase;
  text-decoration: underline;
  transition: all 0.3s ease 0s;
  background: none;
  border: 0;
  padding: 0;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .form-btn button {
    font-size: 18px;
  }
}

.form-btn button:hover {
  color: #89715b;
}

/*--
/*  2.3 - Blockquote CSS
/*----------------------------------------*/
.blockquote {
  position: relative;
  margin-bottom: 0;
}

.blockquote p {
  font-size: 36px;
  color: #fff !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blockquote p {
    font-size: 22px;
  }
}

@media only screen and (max-width: 767px) {
  .blockquote p {
    font-size: 20px;
  }
  .blockquote p br {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .blockquote p {
    font-size: 18px;
  }
}

.blockquote-2 {
  position: relative;
  margin-bottom: 50px;
  margin-top: 60px;
}

.blockquote-2 p {
  position: relative;
  color: #fff !important;
  font-size: 24px !important;
  font-weight: 400;
  line-height: 1.6;
  padding-left: 100px;
  border-left: 5px solid #89715b;
  font-family: "Zilla Slab", serif;
}

@media only screen and (max-width: 767px) {
  .blockquote-2 p {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 575px) {
  .blockquote-2 p {
    font-size: 18px !important;
    padding-left: 40px;
  }
  .blockquote-2 p br {
    display: none;
  }
}

.blockquote-2 .author-name {
  position: relative;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding-left: 100px;
  text-transform: uppercase;
}

@media only screen and (max-width: 575px) {
  .blockquote-2 .author-name {
    padding-left: 40px;
  }
}

/*----------------------------------------*/
/*  03. Header CSS
/*----------------------------------------*/
/*--
/*  03.1 - Header Main CSS
/*----------------------------------------*/
.header {
  padding: 53px 0;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .header {
    padding: 33px 0;
  }
}

.header.sticky {
  padding: 25px 0;
  background-color: #111;
}

@media only screen and (max-width: 767px) {
  .header.sticky {
    padding: 20px 0;
  }
}

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-menu-toggler .menu-toggler {
  position: relative;
  font-size: 30px;
  line-height: 1em;
  color: #fff;
}

.offcanvas-menu {
  position: fixed;
  right: 0px;
  top: 0;
  width: 300px;
  max-width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1130;
  transform: translateX(101%);
  transition: all 0.7s ease;
}

.offcanvas-menu.open {
  opacity: 1;
  visibility: visible;
  transition: all 0.7s ease 500ms;
  transform: translateX(0%);
}

.offcanvas-menu .offcanvas-wrapper {
  position: relative;
}

.offcanvas-menu .close-btn {
  position: absolute;
  right: 16px;
  top: 23px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  font-size: 24px;
  color: #000000;
  cursor: pointer;
  z-index: 10;
  transition: all 0.5s ease;
}

.offcanvas-menu .logo {
  position: relative;
  padding: 20px 20px;
  text-align: left;
}

.offcanvas-menu .logo img {
  max-width: 200px;
}

.offcanvas-menu .primary-menu {
  margin-top: 25px;
}

.offcanvas-menu .primary-menu ul {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.offcanvas-menu .primary-menu ul li {
  position: relative;
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.offcanvas-menu .primary-menu ul li a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 14px 20px;
  font-size: 15px;
  color: #404040;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 0.5s ease;
}

.offcanvas-menu .primary-menu ul li .mobile-menu-expand {
  display: block;
  position: absolute;
  width: 52px;
  height: 52px;
  top: 0;
  right: 0;
}

.offcanvas-menu .primary-menu ul li .mobile-menu-expand::before {
  position: absolute;
  content: '';
  width: 8px;
  height: 8px;
  border-left: 1px solid #404040;
  border-bottom: 1px solid #404040;
  display: block;
  position: absolute;
  top: 18px;
  right: 20px;
  transform: rotate(-45deg);
}

.offcanvas-menu .primary-menu ul li .mobile-menu-expand::after {
  content: '';
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 24px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.offcanvas-menu .primary-menu ul li .sub-menu li:last-child {
  border-bottom: 0;
}

.offcanvas-menu .primary-menu ul li:hover > a {
  color: #89715b;
}

.overlay {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1120;
  background: rgba(0, 0, 0, 0.9);
  transform: translateX(101%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.7s ease 500ms;
}

.overlay.open {
  opacity: 1;
  visibility: visible;
  transition: all 0.7s ease;
  transform: translateX(0%);
}

/*----------------------------------------*/
/*  04. Page CSS
/*----------------------------------------*/
/*--
/*  4.1 - Slider CSS
/*----------------------------------------*/
.slider-section {
  position: relative;
}

.slider-section .social {
  position: absolute;
  bottom: 70px;
  left: 70px;
  display: flex;
  z-index: 6;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-section .social {
    left: 40px;
    bottom: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-section .social {
    left: 20px;
    bottom: 20px;
  }
}

.slider-section .social li + li {
  margin-left: 45px;
}

@media only screen and (max-width: 767px) {
  .slider-section .social li + li {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-section .social li + li {
    margin-left: 20px;
  }
}

.slider-section .social li a {
  font-size: 24px;
  color: #fff;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .slider-section .social li a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-section .social li a {
    font-size: 18px;
  }
}

.slider-section .social li a:hover {
  color: #89715b;
}

.single-slider {
  height: 820px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 3;
}

@media only screen and (min-width: 1600px) {
  .single-slider {
    height: 965px;
  }
}

@media only screen and (max-width: 767px) {
  .single-slider {
    height: 720px;
  }
}

@media only screen and (max-width: 575px) {
  .single-slider {
    height: 680px;
  }
}

.single-slider .line {
  width: 1px;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  z-index: -2;
  display: block;
  opacity: 0.15;
}

.single-slider .line:nth-of-type(1) {
  left: 25%;
}

.single-slider .line:nth-of-type(2) {
  left: 50%;
}

.single-slider .line:nth-of-type(3) {
  left: 75%;
}

.single-slider::before {
  position: absolute;
  content: '';
  background: #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.3;
}

.slider-content {
  opacity: 0;
}

.slider-content .sub-title {
  font-size: 24px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  color: #fff;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content .sub-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content .sub-title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-content .sub-title {
    font-size: 16px;
  }
}

.slider-content .main-title {
  font-size: 130px;
  font-weight: 700;
  color: #fff;
  line-height: 1;
  margin-top: 53px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .slider-content .main-title {
    font-size: 100px;
    margin-top: 43px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content .main-title {
    font-size: 80px;
    margin-top: 43px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content .main-title {
    font-size: 70px;
    margin-top: 33px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-content .main-title {
    font-size: 40px;
  }
}

.slider-content p {
  font-size: 18px;
  font-family: "Inter", sans-serif;
  color: #fff;
  margin-top: 50px;
  margin-bottom: 75px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-content p {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .slider-content p {
    margin: 30px 0;
  }
}

@media only screen and (max-width: 575px) {
  .slider-content p {
    font-size: 16px;
  }
}

.slider-content .slider-btn {
  font-size: 24px;
  font-weight: 700;
  font-family: "Zilla Slab", serif;
  color: #fff;
  text-transform: uppercase;
  text-decoration: underline;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-content .slider-btn {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-content .slider-btn {
    font-size: 18px;
  }
}

.slider-content .slider-btn:hover {
  color: #89715b;
}

.slider-active .swiper-button-next,
.slider-active .swiper-button-prev {
  top: auto;
  bottom: 55px;
  left: auto;
  right: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-active .swiper-button-next,
  .slider-active .swiper-button-prev {
    bottom: 35px;
    right: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-active .swiper-button-next,
  .slider-active .swiper-button-prev {
    bottom: 15px;
    right: 20px;
  }
}

.slider-active .swiper-button-next::after,
.slider-active .swiper-button-prev::after {
  display: none;
}

.slider-active .swiper-button-next i,
.slider-active .swiper-button-prev i {
  font-size: 60px;
  color: #fff;
  line-height: 1;
}

@media only screen and (max-width: 767px) {
  .slider-active .swiper-button-next i,
  .slider-active .swiper-button-prev i {
    font-size: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .slider-active .swiper-button-next i,
  .slider-active .swiper-button-prev i {
    font-size: 30px;
  }
}

.slider-active .swiper-button-prev {
  right: 177px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .slider-active .swiper-button-prev {
    right: 110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) and (max-width: 575px), only screen and (max-width: 767px) and (max-width: 575px) {
  .slider-active .swiper-button-prev {
    right: 60px;
  }
}

.animation-style-01.swiper-slide-active .slider-content {
  opacity: 1;
}

.animation-style-01.swiper-slide-active .slider-content .sub-title {
  animation-name: fadeInUp;
  animation-delay: 0.5s;
  animation-duration: 1.3s;
  animation-fill-mode: both;
}

.animation-style-01.swiper-slide-active .slider-content .main-title {
  animation-name: fadeInUp;
  animation-delay: 1s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-01.swiper-slide-active .slider-content p {
  animation-name: fadeInUp;
  animation-delay: 1.5s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animation-style-01.swiper-slide-active .slider-content .slider-btn {
  animation-name: fadeInLeft;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-fill-mode: both;
}

/*--
/*  4.2 - Clients & About CSS
/*----------------------------------------*/
.client-section {
  position: relative;
  z-index: 2;
}

.client-wrapper {
  padding: 75px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .client-wrapper {
    padding: 55px 0;
  }
}

@media only screen and (max-width: 767px) {
  .client-wrapper {
    padding: 35px 0;
  }
}

.client-wrapper .image-box {
  opacity: 0.5;
  transition: all 0.3s ease 0s;
}

.client-wrapper .image-box:hover {
  opacity: 1;
}

.about-section {
  position: relative;
}

.about-images {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 116%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .about-images {
    position: relative;
    width: 720px;
    height: 727px;
    margin: 0 auto;
    margin-bottom: 40px;
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  .about-images {
    width: 540px;
    height: 545px;
  }
}

@media only screen and (max-width: 575px) {
  .about-images {
    width: 320px;
    height: 323px;
  }
}

.about-content .title {
  font-size: 48px;
  font-weight: 700;
  color: #89715b;
  text-transform: uppercase;
}

@media only screen and (max-width: 575px) {
  .about-content .title {
    font-size: 28px;
  }
}

.about-content .about-text {
  font-size: 20px;
  line-height: 1.6;
  margin-top: 36px;
}

@media only screen and (max-width: 575px) {
  .about-content .about-text {
    font-size: 16px;
  }
}

.about-content p {
  color: #fff;
  margin-bottom: 25px;
}

.about-content .signechar {
  padding-top: 20px;
}

.about-content .name {
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-transform: uppercase;
  margin-top: 15px;
}

.about-banner-section {
  position: relative;
}

.about-banner-section .social {
  position: absolute;
  bottom: 70px;
  left: 70px;
  z-index: 6;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .about-banner-section .social {
    left: 40px;
    bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .about-banner-section .social {
    display: flex;
  }
}

@media only screen and (max-width: 575px) {
  .about-banner-section .social {
    left: 20px;
    bottom: 20px;
  }
}

.about-banner-section .social li + li {
  margin-top: 15px;
}

@media only screen and (max-width: 767px) {
  .about-banner-section .social li + li {
    margin-top: 0px;
    margin-left: 20px;
  }
}

.about-banner-section .social li a {
  font-size: 18px;
  color: #fff;
  transition: all 0.3s ease 0s;
}

.about-banner-section .social li a:hover {
  color: #89715b;
}

.about-banner {
  height: 820px;
  z-index: 3;
  background-color: #070707;
  position: relative;
}

@media only screen and (min-width: 1600px) {
  .about-banner {
    height: 965px;
  }
}

@media only screen and (max-width: 767px) {
  .about-banner {
    height: 720px;
  }
}

@media only screen and (max-width: 575px) {
  .about-banner {
    height: 680px;
  }
}

.about-banner-image {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 55%;
  text-align: left;
  z-index: -1;
}

@media only screen and (max-width: 767px) {
  .about-banner-image {
    display: none;
  }
}

.about-banner-content .banner-title {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
}

.about-banner-content .banner-title span {
  color: #89715b;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .about-banner-content .banner-title {
    font-size: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-banner-content .banner-title {
    font-size: 42px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .about-banner-content .banner-title {
    font-size: 38px;
  }
}

@media only screen and (max-width: 575px) {
  .about-banner-content .banner-title {
    font-size: 24px;
  }
  .about-banner-content .banner-title br {
    display: none;
  }
}

.about-banner-content .banner-social {
  padding-top: 55px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .about-banner-content .banner-social {
    padding-top: 45px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-banner-content .banner-social {
    padding-top: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .about-banner-content .banner-social {
    padding-top: 25px;
  }
}

.about-banner-content .banner-social p {
  margin-bottom: 2px;
}

.about-banner-content .banner-social ul {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .about-banner-content .banner-social ul {
    flex-wrap: wrap;
  }
}

.about-banner-content .banner-social ul li {
  position: relative;
}

.about-banner-content .banner-social ul li + li::before {
  content: '-';
  font-size: 16px;
  color: #fff;
  margin: 0 10px;
  font-weight: 700;
  line-height: 1;
}

.about-banner-content .banner-social ul li a {
  font-size: 24px;
  font-weight: 700;
  font-family: "Zilla Slab", serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-banner-content .banner-social ul li a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .about-banner-content .banner-social ul li a {
    font-size: 16px;
  }
}

.about-banner-content .banner-social ul li a.twitter {
  color: #00ccff;
}

.about-banner-content .banner-social ul li a.behance {
  color: #0066ff;
}

.about-banner-content .banner-social ul li a.dribbble {
  color: #ff0066;
}

.about-banner-content .banner-social ul li a.github {
  color: #ffffff;
}

.about-banner-content .banner-service {
  padding-top: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .about-banner-content .banner-service {
    padding-top: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-banner-content .banner-service {
    padding-top: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .about-banner-content .banner-service {
    padding-top: 30px;
  }
}

.about-banner-content .banner-service .service-text {
  margin-top: 20px;
}

.about-banner-content .banner-service .service-text a {
  font-size: 24px;
  font-weight: 700;
  font-family: "Zilla Slab", serif;
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .about-banner-content .banner-service .service-text a br {
    display: none;
  }
}

/*--
/*  4.3 - Why Choose & Counter CSS
/*----------------------------------------*/
.why-choose-wrapper {
  padding-top: 65px;
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .why-choose-wrapper {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .why-choose-wrapper {
    padding-bottom: 60px;
  }
}

.why-choose-wrapper .row .col .service-box {
  margin-left: -1px;
}

@media only screen and (max-width: 575px) {
  .why-choose-wrapper .row .col .service-box {
    margin-left: 0;
    margin-top: -1px;
  }
}

.service-box {
  background-color: #090909;
  transition: all 0.3s ease 0s;
  padding: 30px;
  border: 1px solid #313131;
  min-height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-box {
    padding: 25px;
  }
}

.service-box::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #89715b;
  z-index: -1;
  transform: scaleX(0);
  transition: all 0.3s ease 0s;
}

.service-box .box-content .title a {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  line-height: 1.5;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service-box .box-content .title a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .service-box .box-content .title a {
    font-size: 18px;
  }
}

.service-box .box-content p {
  margin-top: 15px;
  transition: all 0.3s ease 0s;
}

.service-box .box-icon i {
  font-size: 48px;
  line-height: 1;
  color: #89715b;
  display: block;
  transition: all 0.3s ease 0s;
}

.service-box .box-icon .more {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 35px;
  transition: all 0.3s ease 0s;
}

.service-box.active {
  border-color: #89715b;
}

.service-box.active::before {
  transform: scaleX(1);
}

.service-box.active .box-content p {
  color: #fff;
}

.service-box.active .box-icon i {
  color: #fff;
}

.counter-section {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-section {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .counter-section {
    padding-top: 30px;
  }
}

.counter-box {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 25px;
}

@media only screen and (max-width: 767px) {
  .counter-box {
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  .counter-box {
    display: flex;
  }
}

.counter-box .counter-count .count {
  font-size: 50px;
  color: #89715b;
  font-weight: 600;
  display: inline-block;
  line-height: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-box .counter-count .count {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .counter-box .counter-count .count {
    font-size: 40px;
  }
}

.counter-box .counter-content {
  padding-left: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter-box .counter-content {
    padding-left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .counter-box .counter-content {
    padding-left: 0;
    padding-top: 5px;
  }
}

@media only screen and (max-width: 575px) {
  .counter-box .counter-content {
    padding-top: 0;
    padding-left: 20px;
  }
}

.counter-box .counter-content p {
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 600;
  line-height: 1.4;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .counter-box .counter-content p {
    font-size: 14px;
  }
}

/*--
/*  4.4 - Work CSS
/*----------------------------------------*/
.work-tabs-menu {
  padding-bottom: 2px;
}

.work-tabs-menu .nav {
  margin-left: -30px;
  margin-right: -30px;
}

@media only screen and (max-width: 575px) {
  .work-tabs-menu .nav {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.work-tabs-menu .nav li {
  padding: 0 30px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 575px) {
  .work-tabs-menu .nav li {
    padding: 0 10px;
  }
}

.work-tabs-menu .nav li a {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.75);
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 575px) {
  .work-tabs-menu .nav li a {
    font-size: 14px;
  }
}

.work-tabs-menu .nav li a::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}

.work-tabs-menu .nav li a:hover, .work-tabs-menu .nav li a.active {
  color: #fff;
}

.work-tabs-menu .nav li a:hover::before, .work-tabs-menu .nav li a.active::before {
  opacity: 1;
  visibility: visible;
}

.work-tab-content {
  padding-top: 68px;
}

.tab-carousel {
  background-color: rgba(255, 255, 255, 0.35);
}

.single-project-slide {
  position: relative;
  margin-bottom: -1px;
}

.single-project-slide .thumb a {
  display: block;
  position: relative;
}

.single-project-slide .thumb a::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
  background-color: rgba(0, 0, 0, 0.35);
}

.single-project-slide .thumb a img {
  width: 100%;
}

.single-project-slide .content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .single-project-slide .content {
    padding: 30px 30px;
  }
}

.single-project-slide .content::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 0;
  background-color: #141414;
  left: 0;
  bottom: 0;
  transition: all 0.3s ease 0s;
}

.single-project-slide .content .subtitle {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  font-family: "Poppins", sans-serif;
}

.single-project-slide .content .title {
  font-size: 34px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-project-slide .content .title {
    font-size: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .single-project-slide .content .title {
    font-size: 22px;
  }
}

.single-project-slide .content .title a {
  color: #fff;
}

.single-project-slide:hover .content::before {
  height: 100%;
  transition: .6s;
}

.single-project-slide:hover .content .subtitle {
  color: rgba(255, 255, 255, 0.75);
  position: relative;
  z-index: 9;
}

.single-project-slide:hover .content .title {
  position: relative;
  z-index: 9;
}

.single-project-slide:hover .content .title a {
  color: #89715b;
}

.tab-carousel {
  position: relative;
}

.tab-carousel .tab-carousel-prev,
.tab-carousel .tab-carousel-next {
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  transition: all 0.3s ease 0s;
}

.tab-carousel .tab-carousel-prev:focus,
.tab-carousel .tab-carousel-next:focus {
  border: 0px;
  outline: 0px;
}

.tab-carousel .tab-carousel-prev:after,
.tab-carousel .tab-carousel-next:after {
  display: none;
}

.tab-carousel .tab-carousel-prev i,
.tab-carousel .tab-carousel-next i {
  opacity: 0;
  visibility: hidden;
  font-size: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  width: 40px;
  height: 40px;
}

.tab-carousel .tab-carousel-prev i:hover,
.tab-carousel .tab-carousel-next i:hover {
  color: #fff;
  background: #89715b !important;
  transition: .3s;
}

.tab-carousel:hover .tab-carousel-prev i,
.tab-carousel:hover .tab-carousel-next i {
  opacity: 1;
  visibility: visible;
  background: #333333;
  transition: all 0.3s ease 0s;
}

.tab-carousel:hover .tab-carousel-prev {
  left: 70px;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-carousel:hover .tab-carousel-prev {
    left: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-carousel:hover .tab-carousel-prev {
    left: 20px;
  }
}

.tab-carousel:hover .tab-carousel-next {
  right: 70px;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-carousel:hover .tab-carousel-next {
    right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-carousel:hover .tab-carousel-next {
    right: 20px;
  }
}

/*--
/*  4.5 - Achieved CSS
/*----------------------------------------*/
.achieved-year {
  padding-top: 57px;
}

.year-text {
  margin-top: 30px;
}

.year-text p {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}

.achieved-item {
  margin-top: 30px;
}

.achieved-item .sub-title {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.75);
}

@media only screen and (max-width: 575px) {
  .achieved-item .sub-title {
    font-size: 14px;
  }
}

.achieved-item .title a {
  font-size: 36px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 575px) {
  .achieved-item .title a {
    font-size: 24px;
  }
}

.achieved-item .title a span {
  color: #fff;
}

.achieved-item .title a:hover {
  color: #fff;
}

/*--
/*  4.6 - Testimonial CSS
/*----------------------------------------*/
.testimonial-wrapper {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.single-testimonial {
  background-color: #090909;
  padding: 90px 35px 45px;
  position: relative;
}

.single-testimonial i {
  font-size: 40px;
  line-height: 1;
  color: #89715b;
  position: absolute;
  top: 32px;
  right: 40px;
}

.single-testimonial .testimonial-content .name a {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
}

.single-testimonial .testimonial-content .name a:hover {
  color: #89715b;
}

.single-testimonial .testimonial-content .name span {
  font-size: 20px;
  font-weight: 300;
}

.single-testimonial .testimonial-content p {
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 0;
}

.single-testimonial::before {
  position: absolute;
  width: 0;
  height: 5px;
  background: #89715b;
  content: "";
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  transition: .6s;
}

.single-testimonial:hover::before {
  width: 100%;
  transition: .6s;
}

.testimonial-carousel {
  padding-top: 80px;
  position: relative;
}

.testimonial-carousel .swiper-button-prev,
.testimonial-carousel .swiper-button-next {
  display: flex;
  align-items: center;
  top: 0;
}

.testimonial-carousel .swiper-button-prev:focus,
.testimonial-carousel .swiper-button-next:focus {
  border: 0px;
  outline: 0px;
}

.testimonial-carousel .swiper-button-prev:after,
.testimonial-carousel .swiper-button-next:after {
  display: none;
}

.testimonial-carousel .swiper-button-prev i,
.testimonial-carousel .swiper-button-next i {
  opacity: 1;
  visibility: visible;
  font-size: 30px;
  color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  transition: all 0.3s ease 0s;
}

.testimonial-carousel .swiper-button-prev i:hover,
.testimonial-carousel .swiper-button-next i:hover {
  color: #fff;
}

.testimonial-carousel .swiper-button-prev {
  left: auto;
  right: 35px;
}

.testimonial-carousel .swiper-button-next {
  right: 0px;
}

/*--
/*  4.7 - Skills CSS
/*----------------------------------------*/
.skills-wrapper {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.skill-bar-wrapper {
  margin-top: -5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .skill-bar-wrapper {
    padding-top: 30px;
  }
}

.skill-bar-wrapper .single-skills-bar + .single-skills-bar {
  padding-top: 18px;
}

.single-skills-bar .title {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
  margin-bottom: 10px;
  display: inline-block;
  color: #fff;
}

.single-skills-bar .skill-bar {
  position: relative;
  width: 100%;
  height: 14px;
}

.single-skills-bar .skill-bar .bar-inner {
  position: relative;
  width: 100%;
  height: 14px;
  background: #fff;
}

.single-skills-bar .skill-bar .bar-inner .bar {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 14px;
  background-color: #89715b;
  transition: all 2s ease-out 0s;
}

.single-skills-bar .skill-bar .bar-inner .bar .skill-percentage {
  position: absolute;
  right: 0px;
  top: -35px;
  float: right;
  font-weight: 400;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
}

/*--
/*  4.8 - Pricing CSS
/*----------------------------------------*/
.pricing-banner-section {
  height: 820px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 3;
}

@media only screen and (min-width: 1600px) {
  .pricing-banner-section {
    height: 965px;
  }
}

@media only screen and (max-width: 767px) {
  .pricing-banner-section {
    height: 500px;
  }
}

@media only screen and (max-width: 575px) {
  .pricing-banner-section {
    height: 420px;
  }
}

.pricing-banner-section::before {
  position: absolute;
  content: '';
  background: #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.45;
}

.pricing-banner-content {
  padding-top: 247px;
}

@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .pricing-banner-content {
    padding-top: 187px;
  }
}

@media only screen and (max-width: 575px) {
  .pricing-banner-content {
    padding-top: 147px;
  }
}

.pricing-banner-content .title {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
}

.pricing-banner-content .title span {
  color: #89715b;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .pricing-banner-content .title {
    font-size: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pricing-banner-content .title {
    font-size: 42px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .pricing-banner-content .title {
    font-size: 38px;
  }
}

@media only screen and (max-width: 575px) {
  .pricing-banner-content .title {
    font-size: 24px;
  }
  .pricing-banner-content .title br {
    display: none;
  }
}

.pricing-banner-content p {
  margin-bottom: 0;
  margin-top: 45px;
}

.pricing-table-wrapper {
  margin-top: -320px;
  position: relative;
  z-index: 5;
}

@media only screen and (max-width: 767px) {
  .pricing-table-wrapper {
    margin-top: 0;
    padding-top: 30px;
  }
}

.pricing-table-wrapper .row .col .single-pricing-box {
  margin-left: -1px;
}

.single-pricing-box {
  background-color: #090909;
  border: 1px solid #89715b;
  text-align: center;
  position: relative;
}

.single-pricing-box .pricing-iner {
  padding: 100px 60px 60px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-pricing-box .pricing-iner {
    padding: 80px 40px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .single-pricing-box .pricing-iner {
    padding: 70px 30px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single-pricing-box {
    margin-top: 30px;
  }
}

.single-pricing-box .popular {
  position: absolute;
  left: 50%;
  top: 0px;
  color: #89715b;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 3px;
  padding: 7px 30px;
  text-transform: uppercase;
  transform: translateX(-50%);
  border-radius: 0px 0px 35px 35px;
  background-color: #33281e;
}

@media only screen and (max-width: 575px) {
  .single-pricing-box .popular {
    font-size: 14px;
    padding: 5px 30px;
  }
}

.single-pricing-box .icon-box {
  position: relative;
  color: #89715b;
  font-size: 100px;
  line-height: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-pricing-box .icon-box {
    font-size: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .single-pricing-box .icon-box {
    font-size: 60px;
  }
}

.single-pricing-box .title {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
  margin-top: 62px;
  letter-spacing: 2px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .single-pricing-box .title {
    margin-top: 42px;
  }
}

.single-pricing-box .price {
  font-size: 56px;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 1;
  margin-top: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-pricing-box .price {
    font-size: 42px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .single-pricing-box .price {
    font-size: 34px;
  }
}

.single-pricing-box .price sup {
  font-size: 20px;
  vertical-align: top;
  top: 15px;
  left: -5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .single-pricing-box .price sup {
    font-size: 15px;
    top: 10px;
  }
}

.single-pricing-box .price span {
  font-size: 20px;
  font-weight: 400;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .single-pricing-box .price span {
    font-size: 15px;
  }
}

.single-pricing-box .price-list {
  position: relative;
  margin-bottom: 25px;
  margin-top: 45px;
}

.single-pricing-box .price-list li {
  position: relative;
  font-size: 14px;
  padding: 22px 0px;
  text-transform: uppercase;
  border-top: 1px solid #89715b;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .single-pricing-box .price-list li br {
    display: none;
  }
}

.single-pricing-box .btn {
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
  height: 50px;
  line-height: 46px;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

/*--
/*  4.9 - Projects CSS
/*----------------------------------------*/
.single-project {
  margin-top: 80px;
}

.single-project .project-images {
  overflow: hidden;
}

.single-project .project-images a {
  display: block;
  position: relative;
}

.single-project .project-images a img {
  width: 100%;
  transition: all 0.3s ease 0s;
}

.single-project .project-images a::before {
  position: absolute;
  content: '';
  background: #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease 0s;
}

.single-project .project-content {
  background-color: #090909;
  padding: 20px 35px 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .single-project .project-content {
    display: block;
    padding: 10px 25px 25px;
  }
}

.single-project .project-content .title a {
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  transition: all 0.3s ease 0s;
  margin-top: 8px;
  display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .single-project .project-content .title a {
    font-size: 20px;
  }
}

.single-project .project-content .title a:hover {
  color: #89715b;
}

.single-project .project-content .category {
  font-size: 16px;
  font-family: "Inter", sans-serif;
  margin-top: 8px;
  white-space: nowrap;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .single-project .project-content .category {
    font-size: 14px;
  }
}

.single-project:hover .project-images a img {
  transform: scale(1.05) rotate(1deg);
}

.single-project:hover .project-images a::before {
  opacity: 0.35;
  visibility: visible;
}

/*--
/*  4.10 - Projects Details CSS
/*----------------------------------------*/
.project-details-section {
  padding-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .project-details-section {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .project-details-section {
    padding-top: 50px;
  }
}

.project-details-section .details-label {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 16px;
  white-space: nowrap;
}

.project-details-section p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

.details-images img {
  width: 100%;
}

.details-challenge .title {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  margin-top: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .details-challenge .title {
    font-size: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .details-challenge .title {
    font-size: 42px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .details-challenge .title {
    font-size: 38px;
  }
}

@media only screen and (max-width: 575px) {
  .details-challenge .title {
    font-size: 30px;
  }
  .details-challenge .title br {
    display: none;
  }
}

.single-info {
  margin-top: 30px;
}

.single-info .details-label {
  font-size: 14px;
}

.single-info p {
  font-size: 20px;
  color: #fff;
  margin-top: 25px;
}

@media only screen and (max-width: 575px) {
  .single-info p {
    font-size: 16px;
  }
}

.details-middle-content {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.details-middle-content .title {
  font-size: 48px;
  font-weight: 700;
  color: #fff;
  margin-top: -15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .details-middle-content .title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .details-middle-content .title {
    font-size: 30px;
  }
}

.details-middle-content p {
  font-size: 18px;
  margin-top: 35px;
}

.column-images {
  padding-top: 58px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .column-images {
    padding-top: 38px;
  }
}

@media only screen and (max-width: 767px) {
  .column-images {
    padding-top: 18px;
  }
}

.author-info {
  margin-top: 73px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .author-info {
    margin-top: 53px;
  }
}

@media only screen and (max-width: 767px) {
  .author-info {
    margin-top: 43px;
  }
}

.author-info .name {
  font-size: 400px;
  font-size: 24px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 1px;
}

.author-info p {
  color: rgba(255, 255, 255, 0.75);
  margin-top: 10px;
}

.details-social {
  display: flex;
  align-items: center;
  padding-top: 95px;
  padding-bottom: 155px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .details-social {
    padding-bottom: 115px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .details-social {
    padding-bottom: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .details-social {
    padding-top: 75px;
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .details-social {
    padding-top: 55px;
    padding-bottom: 55px;
  }
}

.details-social .social {
  display: flex;
  padding-left: 20px;
}

@media only screen and (max-width: 575px) {
  .details-social .social {
    padding-left: 0;
  }
}

.details-social .social li {
  margin-left: 30px;
}

.details-social .social li a {
  font-size: 24px;
  color: #fff;
  line-height: 1;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 575px) {
  .details-social .social li a {
    font-size: 20px;
  }
}

.details-social .social li a:hover {
  color: #89715b;
}

.reservation-form-wrapper {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
}

.reservation-form-wrapper .title {
  font-size: 48px;
  font-weight: 700;
  color: #fff;
  margin-top: -15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .reservation-form-wrapper .title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .reservation-form-wrapper .title {
    font-size: 30px;
  }
}

.reservation-form {
  padding: 20px 50px 50px;
  border: 1px solid rgba(255, 255, 255, 0.15);
  margin-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .reservation-form {
    margin-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .reservation-form {
    margin-top: 60px;
  }
}

@media only screen and (max-width: 575px) {
  .reservation-form {
    padding: 0px 30px 30px;
  }
}

.next {
  margin-top: -10px;
  margin-bottom: -10px;
}

.next a {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  display: inline-block;
  line-height: 1;
  transition: all 0.3s ease 0s;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .next a {
    font-size: 50px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .next a {
    font-size: 42px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .next a {
    font-size: 38px;
  }
}

@media only screen and (max-width: 575px) {
  .next a {
    font-size: 30px;
  }
}

.next a:hover {
  color: #89715b;
}

/*--
/*  4.11 - Blog CSS
/*----------------------------------------*/
.single-blog {
  margin-top: 80px;
}

.single-blog .blog-images a {
  display: block;
}

.single-blog .blog-images a img {
  width: 100%;
}

.single-blog .blog-content {
  padding-top: 25px;
}

.single-blog .blog-content .meta {
  display: flex;
}

.single-blog .blog-content .meta li {
  position: relative;
}

.single-blog .blog-content .meta li + li::before {
  content: '/';
  color: #333333;
  margin: 0 15px;
  font-size: 13px;
}

.single-blog .blog-content .meta li a {
  font-size: 13px;
  color: #999;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}

.single-blog .blog-content .meta li a:hover {
  color: #f89200;
}

.single-blog .blog-content .title a {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  margin-top: 15px;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 575px) {
  .single-blog .blog-content .title a {
    font-size: 24px;
  }
}

.single-blog .blog-content .title a:hover {
  color: #89715b;
}

.single-blog .blog-content p {
  margin-top: 15px;
}

/*--
/*  4.12 - Blog Details CSS
/*----------------------------------------*/
.blog-details-content {
  padding-top: 90px;
  padding-bottom: 150px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .blog-details-content {
    padding-bottom: 110px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-details-content {
    padding-bottom: 90px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-content {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-content {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.blog-details-content .blog-title {
  position: relative;
  color: #fff;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  font-size: 24px;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .blog-details-content .blog-title {
    font-size: 20px;
  }
  .blog-details-content .blog-title br {
    display: none;
  }
}

.blog-details-content p {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  margin-top: 30px;
}

.blog-details-content .post-tags-share {
  padding-top: 70px;
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-content .post-tags-share {
    padding-top: 50px;
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-content .post-tags-share {
    padding-top: 30px;
    padding-bottom: 50px;
  }
}

.blog-details-content .post-tags-share .label {
  font-size: 14px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #fff;
  text-transform: uppercase;
  white-space: nowrap;
  line-height: 1.5;
}

.blog-details-content .post-tags-share .post-tags {
  display: flex;
  justify-content: center;
}

.blog-details-content .post-tags-share .post-tags .tags {
  display: flex;
  flex-wrap: wrap;
}

.blog-details-content .post-tags-share .post-tags .tags li {
  margin-left: 5px;
}

.blog-details-content .post-tags-share .post-tags .tags li a {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s ease 0s;
}

.blog-details-content .post-tags-share .post-tags .tags li a:hover {
  color: #89715b;
}

.blog-details-content .post-tags-share .post-share {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.blog-details-content .post-tags-share .post-share .social {
  display: flex;
  flex-wrap: wrap;
  margin-left: 30px;
}

.blog-details-content .post-tags-share .post-share .social li + li {
  margin-left: 25px;
}

.blog-details-content .post-tags-share .post-share .social li a {
  font-size: 16px;
  font-weight: 400;
  transition: all 0.3s ease 0s;
  color: rgba(255, 255, 255, 0.5);
}

.blog-details-content .post-tags-share .post-share .social li a:hover {
  color: #89715b;
}

.blog-details-content .blog-label {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .blog-details-content .blog-label {
    font-size: 24px;
  }
}

.blog-details-content .related-post {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 90px;
  padding-bottom: 95px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-content .related-post {
    padding-top: 70px;
    padding-bottom: 75px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-content .related-post {
    padding-top: 50px;
    padding-bottom: 55px;
  }
}

.blog-details-content .related-post .single-blog {
  margin-top: 60px;
}

.blog-details-content .related-post .single-blog .blog-content .title a {
  font-size: 24px;
}

.blog-details-content .related-post .single-blog .blog-content .title a:hover {
  color: #89715b;
}

.blog-details-content .comment-post {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 90px;
  padding-bottom: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-content .comment-post {
    padding-top: 70px;
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-content .comment-post {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.blog-details-content .comment-form {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 90px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-content .comment-form {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .blog-details-content .comment-form {
    padding-top: 50px;
  }
}

.single-comment {
  display: flex;
  margin-top: 45px;
}

@media only screen and (max-width: 575px) {
  .single-comment {
    display: block;
  }
}

.single-comment .comment-content {
  flex: 1;
  padding-left: 50px;
}

@media only screen and (max-width: 575px) {
  .single-comment .comment-content {
    padding-top: 25px;
    padding-left: 0;
  }
}

.single-comment .comment-content .comment-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.single-comment .comment-content .comment-info .name {
  font-size: 16px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  color: #fff;
  margin-bottom: 5px;
}

.single-comment .comment-content .comment-info .date {
  font-size: 12px;
  padding-left: 5px;
  margin-bottom: 5px;
}

.single-comment .comment-content p {
  margin-top: 10px;
  margin-bottom: 0;
  font-size: 14px;
}

.single-comment .comment-content .reply {
  font-size: 14px;
  text-transform: uppercase;
  color: #0099ff;
  margin-top: 10px;
  display: inline-block;
}

/*--
/*  4.13 - Contact CSS
/*----------------------------------------*/
.contact-section {
  position: relative;
  display: flex;
  align-items: center;
  padding: 145px 0 146px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .contact-section {
    padding-bottom: 106px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-section {
    padding-bottom: 86px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-section {
    padding-top: 180px;
    padding-bottom: 66px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-section {
    padding-top: 180px;
    padding-bottom: 46px;
  }
}

.contact-section .contact-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
  height: 100%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .contact-section .contact-bg {
    display: none;
  }
}

.contact-info .mail {
  font-size: 60px;
  font-weight: 700;
  font-family: "Zilla Slab", serif;
  color: #89715b;
  display: block;
  line-height: 1.2;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 767px) {
  .contact-info .mail {
    font-size: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .contact-info .mail {
    font-size: 24px;
  }
}

.contact-info .mail a:hover {
  color: #fff;
}

.contact-info .number {
  font-size: 36px;
  font-weight: 700;
  font-family: "Zilla Slab", serif;
  color: #fff;
  display: block;
  line-height: 1.2;
  margin-top: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (max-width: 767px) {
  .contact-info .number {
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .contact-info .number {
    font-size: 20px;
  }
}

.contact-info .number a:hover {
  color: #89715b;
}

.form-message {
  margin-bottom: 0;
}

.form-message.error, .form-message.success {
  font-size: 16px;
  color: #fff;
  background-color: #141414;
  padding: 10px 15px;
  margin-left: 15px;
  margin-top: 15px;
}

.form-message.error {
  color: red;
}

.contact-form {
  padding-top: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-form {
    padding-top: 0px;
  }
}

.contact-form label.error {
  font-size: 13px;
  color: red;
  margin-top: 5px;
}

.contact-social {
  padding-top: 80px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-social {
    padding-top: 40px;
  }
}

.contact-social ul {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .contact-social ul {
    flex-wrap: wrap;
  }
}

.contact-social ul li {
  position: relative;
}

.contact-social ul li + li::before {
  content: '-';
  font-size: 16px;
  color: #fff;
  margin: 0 10px;
  font-weight: 700;
  line-height: 1;
}

.contact-social ul li a {
  font-size: 24px;
  font-weight: 700;
  font-family: "Zilla Slab", serif;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-social ul li a {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .contact-social ul li a {
    font-size: 16px;
  }
}

.contact-social ul li a.twitter {
  color: #00ccff;
}

.contact-social ul li a.behance {
  color: #0066ff;
}

.contact-social ul li a.dribbble {
  color: #ff0066;
}

.contact-social ul li a.github {
  color: #ffffff;
}

/*--
/*  4.14 - FAQ's CSS
/*----------------------------------------*/
.faq-menu {
  padding-top: 53px;
}

.faq-menu .nav li {
  margin-bottom: 12px;
}

.faq-menu .nav li a {
  font-family: "Zilla Slab", serif;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  transition: all 0.3s ease 0s;
  text-transform: uppercase;
}

.faq-menu .nav li a:hover, .faq-menu .nav li a.active {
  color: #89715b;
}

.single-faq {
  padding-top: 55px;
}

.single-faq .title {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .single-faq .title {
    font-size: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .single-faq .title {
    font-size: 24px;
  }
}

.single-faq p {
  margin-top: 18px;
}

/*----------------------------------------*/
/*  05. Widget CSS
/*----------------------------------------*/
/*--
/*  05.1 - Footer Widget CSS
/*----------------------------------------*/
/*--
/*  05.2 - Sidebar CSS
/*----------------------------------------*/
/*----------------------------------------*/
/*  06. Footer CSS
/*----------------------------------------*/
.footer-info-box {
  padding: 275px 0 195px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-info-box {
    padding-top: 230px;
    padding-bottom: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-info-box {
    padding-top: 200px;
    padding-bottom: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-info-box {
    padding-top: 180px;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-info-box {
    padding-top: 150px;
    padding-bottom: 70px;
  }
}

.footer-info-box .title {
  font-size: 24px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 767px) {
  .footer-info-box .title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-info-box .title {
    font-size: 16px;
  }
}

.footer-info-box .mail a {
  font-size: 60px;
  font-weight: 700;
  color: #89715b;
  margin-top: 10px;
  display: inline-block;
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 767px) {
  .footer-info-box .mail a {
    font-size: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-info-box .mail a {
    font-size: 20px;
  }
}

.footer-info-box .mail a:hover {
  color: #fff;
}

.footer-copyright-social {
  display: flex;
  justify-content: space-between;
  padding-bottom: 70px;
  padding-top: 100px;
}

@media only screen and (max-width: 767px) {
  .footer-copyright-social {
    display: block;
    text-align: center;
  }
}

.footer-copyright {
  padding-bottom: 20px;
}

.footer-copyright span {
  color: #fff;
}

.footer-copyright i {
  color: #da2929;
}

.footer-copyright a {
  color: #fff;
}

.footer-copyright a:hover {
  color: #89715b;
}

.footer-social {
  padding-bottom: 20px;
}

.footer-social .social {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .footer-social .social {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.footer-social .social li + li {
  margin-left: 35px;
}

.footer-social .social li a {
  font-size: 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 575px) {
  .footer-social .social li a {
    font-size: 14px;
  }
}

.footer-social .social li a.twitter {
  color: #00ccff;
}

.footer-social .social li a.behance {
  color: #0066ff;
}

.footer-social .social li a.dribbble {
  color: #ff0066;
}

.footer-social .social li a.github {
  color: #ffffff;
}

.back-to-top {
  background-color: #090909;
  color: #fff;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: none;
  text-align: center;
  text-decoration: none;
  transition: all 500ms ease-in-out;
  position: fixed;
  right: 50px;
  bottom: 50px;
  font-size: 24px;
  z-index: 28;
  box-shadow: 0 0 7px rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 575px) {
  .back-to-top {
    right: 20px;
    bottom: 20px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 20px;
  }
}

.back-to-top:hover {
  background-color: #89715b;
  color: #fff;
}
